import React from "react";

interface IProps {
  color?: string;
}

const ArrowPrice = ({ color = "#F8F9FA" }: IProps) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <path
          d="M2.25105 9.75L10.0211 9.75C10.6136 9.75 10.9736 9.0975 10.6511 8.595L6.76605 2.49C6.47355 2.025 5.79855 2.025 5.49855 2.49L1.62105 8.595C1.29855 9.0975 1.65855 9.75 2.25105 9.75Z"
          fill={color}
        />
      </svg>
    </>
  );
};

export default ArrowPrice;
