export const SOCKET_DEFAULT_EVENTS = {
  CONNECT: "connect",
  DISCONNECT: "disconnect",
  CONNECT_ERROR: "connect_error",
  CONNECT_TIMEOUT: "connect_timeout",
  // RECONNECT: "reconnect",
  // RECONNECT_ERROR: "reconnect_error",
  // RECONNECT_FAILED: "reconnect_failed",
  // RECONNECT_ATTEMPT: "reconnect_attempt",
  PONG: "pong",
  PING: "ping",
  ERROR: "error",
};

export const GROUP_EVENTS = {
  APPLYING_PRICES_SUCCESSFUL: "GROUP.APPLYING_PRICES_SUCCESSFUL",
};

export const ITEM_EVENTS = {
  APPLYING_PRICE_SUCCESSFUL: "ITEM.APPLYING_PRICE_SUCCESSFUL",
};
