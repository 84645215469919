import { ButtonStyled, InputCustom } from "@shared/ui/components";
import "./teamManagement.scss";
import { Plus, Search } from "@shared/ui/assets";
import { useEffect, useState } from "react";
import { FilterItem } from "@features/filterItem";
import { IFilterItem, ISortParams, SortParams } from "@shared/interfaces";
import { UserRolesEnum, useSettingsStore } from "@entities/settings";
import TableUsers from "./TableUsers/TableUsers";
import {
  DEFAULT_LIMIT_PAGINATION,
  DEFAULT_OFFSET_PAGINATION,
  DIRECTION_PARAM,
  FILTER_BY_PARAM,
  LIMIT_PARAM,
  OFFSET_PARAM,
  ORDER_BY_PARAM,
  SEARCH_PARAM,
} from "@shared/constants";
import InviteUsersPopup from "./InviteUsersPopup/InviteUsersPopup";
import { useDebouncedCallback } from "use-debounce";
import { useSearchParams } from "react-router-dom";
import { getParamsAsObject } from "@shared/utils";

const ALL_USERS = "all_users";

const filterItems: IFilterItem[] = [
  {
    name: `All users 4`,
    value: ALL_USERS,
  },
  {
    name: `Admin 1`,
    value: UserRolesEnum.ADMIN,
  },
  {
    name: `Editor 2`,
    value: UserRolesEnum.EDITOR,
  },
  // {
  //   name: `Viewer 1`,
  //   value: UserRolesEnum.VIEWER,
  // },
];

const TeamManagement = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [inputValue, setInputValue] = useState<string>(
    searchParams.get(SEARCH_PARAM) || ""
  );

  const [isOpenInviteUsersPopup, setOpenInviteUsersPopup] =
    useState<boolean>(false);

  const limitQuery = searchParams.get(LIMIT_PARAM);
  const offsetQuery = searchParams.get(OFFSET_PARAM);
  const filterQuery = searchParams.get(FILTER_BY_PARAM);
  const orderByQuery = searchParams.get(ORDER_BY_PARAM);
  const directionQuery = searchParams.get(DIRECTION_PARAM);

  const limitInitial = limitQuery ? +limitQuery : DEFAULT_LIMIT_PAGINATION;
  const offsetInitial = offsetQuery ? +offsetQuery : DEFAULT_OFFSET_PAGINATION;
  const filterInitial = filterQuery ? filterQuery : ALL_USERS;
  const orderByInitial = orderByQuery ? orderByQuery : "";
  const directionInitial = directionQuery
    ? (directionQuery as SortParams)
    : null;

  const [currentLimit, setCurrentLimit] = useState(limitInitial);
  const [currentOffset, setCurrentOffset] = useState(offsetInitial);
  const [activeFilter, setActiveFilter] = useState<string>(filterInitial);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: ISortParams | null;
  }>({
    key: orderByInitial,
    direction: directionInitial,
  });

  const { getUsersTable } = useSettingsStore((state) => state);

  const updateParams = (
    newParams: Record<string, string | number | null | undefined>
  ) => {
    const updatedParams = new URLSearchParams(searchParams);
    Object.entries(newParams).forEach(([key, value]: any) => {
      if (value === null || value === undefined || value === "") {
        updatedParams.delete(key);
      } else {
        updatedParams.set(key, value);
      }
    });

    setSearchParams(updatedParams);
  };

  useEffect(() => {
    getUsersTable(getParamsAsObject(searchParams));
  }, [searchParams]);

  const debounced = useDebouncedCallback(async (value) => {
    updateParams({ [SEARCH_PARAM]: value });
  }, 500);

  const onSearchChange = (event: any) => {
    const value = event.target.value;
    setInputValue(value);
    debounced(value.trim());
  };

  const onChangeFilter = (value: string) => {
    setActiveFilter(value);
    updateParams({ [FILTER_BY_PARAM]: value === ALL_USERS ? "" : value });
  };

  return (
    <>
      <InviteUsersPopup
        flag={isOpenInviteUsersPopup}
        onClose={() => setOpenInviteUsersPopup(false)}
      />
      <h2 className="tm-tab-title"> Team Management</h2>
      <div className="tm-block-filters">
        <div className="tm-filters-container">
          <div className="mr-32">
            <InputCustom
              type="text"
              value={inputValue}
              name="group-search"
              placeholder="Search for items"
              onChangeInput={onSearchChange}
              fill="gray-primary-900"
              iconRight={<Search />}
            />
          </div>
          <div className="tm-filters">
            {filterItems.map((item) => (
              <FilterItem
                item={item}
                key={item.value}
                active={activeFilter}
                onClick={(value) => onChangeFilter(value)}
              />
            ))}
          </div>
        </div>
        <ButtonStyled
          type="primary"
          text="Invite users"
          htmlType="button"
          fill="gray-primary-900"
          className="groups-create__btn"
          icon={<Plus />}
          onClick={() => setOpenInviteUsersPopup(true)}
        />
      </div>
      <TableUsers
        setSortConfig={setSortConfig}
        // tableWidth={tableWidth}
        sortConfig={sortConfig}
        setCurrentLimit={setCurrentLimit}
        setCurrentOffset={setCurrentOffset}
        currentOffset={currentOffset}
        currentLimit={currentLimit}
        updateParams={updateParams}
      />
    </>
  );
};

export default TeamManagement;
