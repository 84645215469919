import { IDataTransfer } from "@shared/interfaces";

export enum UserRolesEnum {
  ADMIN = "ADMIN",
  EDITOR = "USER",
  VIEWER = "USER",
}

interface ISettingsUser {}

export interface IInviteUser {
  email: string;
  role: UserRolesEnum;
}

export interface ISettingsStore {
  usersTable: ISettingsUser[];
  inviteUsersErrors: any[];
  recordsCountUsers: number;
  getUsersTable: (params: IDataTransfer) => void;
  saveInviteUser: (inviteUsers: IInviteUser) => Promise<any>;
  deleteUserById: (id: string) => Promise<void>;
  editUserById: (id: string, role: UserRolesEnum) => Promise<void>;
}
