import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./itemPriceElasticity.scss";
import {
  ButtonStyled,
  CustomTooltip,
  InputWithIcons,
} from "@shared/ui/components";
import { Arrow, ArrowPrice, Info } from "@shared/ui/assets";
import CustomGraphElasticity from "../CustomGraphElasticity/CustomGraphElasticity";
import { useFormik } from "formik";
import { useItemInfoStore } from "@entities/itemDetails";
import { Spiner } from "@features/spiner";
import { useGroupsStore } from "@entities/groups";
import { calculateActiveTrend, findRangePercentage } from "@shared/utils";
import { useDebouncedCallback } from "use-debounce";
import { useParams } from "react-router-dom";

interface IProps {
  currentPrice: string;
  productId: string;
  newPrice?: string;
  activeTab: string;
}

const titleTooltip = (
  <CustomTooltip
    title={
      "View price change impacts on sales, profit, and revenue. Hover to explore, click to analyze."
    }
    placement="right"
  >
    <Info />
  </CustomTooltip>
);

const titleTooltipImpact = (
  <CustomTooltip
    title={
      "30-day forecast for current prices and suggested price adjustments. Historical forecast accuracy: 90%."
    }
    placement="right"
  >
    <Info />
  </CustomTooltip>
);

const ItemPriceElasticity: FC<IProps> = memo((props) => {
  const { id } = useParams();
  const { currentPrice, productId, activeTab } = props;
  const [newPrice, setNewPrice] = useState<string>(props.newPrice || "");
  const {
    getElasticityDataGraph,
    datesOfGraphElasticity,
    setDatesOfGraphElasticity,
  } = useItemInfoStore((state) => state);
  const { handleSaveNewPrice, getCountNewPriceReady } = useGroupsStore(
    (state) => state
  );
  const [activeTrend, setActiveTrendState] = useState<any>(null);
  const [isDebounced, setDebounced] = useState<boolean | null>(null);
  const [prevTrend, setPrevTrend] = useState<any>(null);

  useEffect(() => {
    setFieldValue("newPrice", props.newPrice);
    setNewPrice(props.newPrice || "");
    if (!prevTrend && props.newPrice && datesOfGraphElasticity) {
      const range = chooseRangeTrends(datesOfGraphElasticity, props.newPrice);
      if (range) {
        const calculatedTrend = calculateActiveTrend(
          range[0],
          range[1],
          +props.newPrice
        );
        setActiveTrendState(calculatedTrend);
      }
    }
  }, [props.newPrice, productId, activeTab, datesOfGraphElasticity]);

  useEffect(() => {
    setDatesOfGraphElasticity([]);
    // activeTab === "3" &&
    //   !datesOfGraphElasticity.length &&
    getElasticityDataGraph(productId);
  }, [productId]);
  const chooseRangeTrends = (datesOfGraphElasticity: any, newPrice: string) => {
    const index = datesOfGraphElasticity.findIndex(
      (el: any, i: number) =>
        el.price <= newPrice &&
        (i === datesOfGraphElasticity.length - 1 ||
          datesOfGraphElasticity[i + 1].price > newPrice)
    );

    if (index !== -1 && index < datesOfGraphElasticity.length - 1) {
      return [datesOfGraphElasticity[index], datesOfGraphElasticity[index + 1]];
    }

    return null;
  };

  const chooseTrend = datesOfGraphElasticity?.find(
    (el) => +el.price === +newPrice
  );

  const newPriceTrend = activeTrend || chooseTrend;

  const currentPriseTrend = datesOfGraphElasticity?.find(
    (el) => el.price === +currentPrice
  );

  const getMarginBefore = (
    ((+newPriceTrend?.price - +newPriceTrend?.cost) / +newPriceTrend?.price) *
    100
  ).toFixed(2);

  const getMarginAfter = (
    ((+currentPriseTrend?.price - +currentPriseTrend?.cost) /
      +currentPriseTrend?.price) *
    100
  ).toFixed(2);

  const setActiveTrend = (trend: any) => {
    setDebounced(true);
    resetDebounced();
    if (activeTrend) {
      setPrevTrend(activeTrend);
    } else {
      setPrevTrend(newPriceTrend);
    }
    setFieldValue("newPrice", trend.price);
    setActiveTrendState(trend);
  };

  const resetDebounced = useDebouncedCallback(() => {
    setDebounced(false);
  }, 1000);

  const formik = useFormik({
    initialValues: { newPrice: "" },
    onSubmit: async (values) => {
      await handleSaveNewPrice(productId, +values.newPrice);
      setNewPrice(values.newPrice);
      await getCountNewPriceReady(id || "");
    },
  });

  const {
    touched,
    errors,
    setFieldValue,
    isValid,
    values,
    handleBlur,
    setValues,
  } = formik;

  const formatNumber = (number: number) => {
    if (number >= 1000) {
      const formatted = (number / 1000).toFixed(2);
      return `${formatted}k`;
    }
    return number.toString();
  };

  const chooseChangeValuePrice = () => {
    if (prevTrend?.["price"] < newPriceTrend?.["price"]) {
      return (
        <div className="item-arrow-up">
          <ArrowPrice color="green" />
        </div>
      );
    }
    if (prevTrend?.["price"] > newPriceTrend?.["price"]) {
      return (
        <div className="item-arrow-down">
          <ArrowPrice color="red" />
        </div>
      );
    }

    return null;
  };
  const chooseChangeValueSales = () => {
    if (prevTrend?.["sales"] < newPriceTrend?.["sales"]) {
      return (
        <div className="item-arrow-up-sales">
          <ArrowPrice color="green" />
        </div>
      );
    }
    if (prevTrend?.["sales"] > newPriceTrend?.["sales"]) {
      return (
        <div className="item-arrow-down-sales">
          <ArrowPrice color="red" />
        </div>
      );
    }

    return null;
  };
  const chooseChangeValueProfit = () => {
    if (prevTrend?.["profit"] < newPriceTrend?.["profit"]) {
      return (
        <div className="item-arrow-up-profit">
          <ArrowPrice color="green" />
        </div>
      );
    }
    if (prevTrend?.["profit"] > newPriceTrend?.["profit"]) {
      return (
        <div className="item-arrow-down-profit">
          <ArrowPrice color="red" />
        </div>
      );
    }

    return null;
  };
  const chooseChangeValueRevenue = () => {
    if (prevTrend?.["revenue"] < newPriceTrend?.["revenue"]) {
      return (
        <div className="item-arrow-up-revenue">
          <ArrowPrice color="green" />
        </div>
      );
    }
    if (prevTrend?.["revenue"] > newPriceTrend?.["revenue"]) {
      return (
        <div className="item-arrow-down-revenue">
          <ArrowPrice color="red" />
        </div>
      );
    }
    return null;
  };

  const optionImpact: any[] = [
    {
      label: "Margin",
      valueBefore: +getMarginAfter ? `${getMarginAfter}%` : "-",
      valueAfter: +getMarginBefore ? `${getMarginBefore}%` : "-",
      profit: `${findRangePercentage(+getMarginAfter, +getMarginBefore)}`,
      key: "price",
      onEvent: () => chooseChangeValuePrice(),
    },
    {
      label: "Sales",
      valueBefore: +currentPriseTrend?.sales
        ? `${+currentPriseTrend?.sales}`
        : "-",
      valueAfter: +newPriceTrend?.sales ? `${+newPriceTrend?.sales}` : "-",
      profit: `${findRangePercentage(
        +currentPriseTrend?.sales,
        +newPriceTrend?.sales
      )}`,
      key: "sales",
      onEvent: () => chooseChangeValueSales(),
    },
    {
      label: "Profit",
      valueBefore: currentPriseTrend?.profit
        ? `$${formatNumber(currentPriseTrend?.profit)}`
        : "-",
      valueAfter: newPriceTrend?.profit
        ? `$${formatNumber(newPriceTrend?.profit)}`
        : "-",
      profit: `${findRangePercentage(
        +currentPriseTrend?.profit,
        +newPriceTrend?.profit
      )}`,
      key: "profit",
      onEvent: () => chooseChangeValueProfit(),
    },
    {
      label: "Revenue",
      valueBefore: currentPriseTrend?.revenue
        ? `$${formatNumber(currentPriseTrend?.revenue)}`
        : "-",
      valueAfter: newPriceTrend?.revenue
        ? `$${formatNumber(newPriceTrend?.revenue)}`
        : "-",
      profit: `${findRangePercentage(
        +currentPriseTrend?.revenue,
        +newPriceTrend?.revenue
      )}`,
      key: "revenue",
      onEvent: () => chooseChangeValueRevenue(),
    },
  ];

  const isUpdating = true;

  const memoizedSetActiveTrend = (trend: any) => {
    setActiveTrend(trend);
  };

  const chooseChangeValueClass = (key: string) => {
    if (prevTrend?.[key] < newPriceTrend?.[key]) {
      return "item-after positive";
    }
    if (prevTrend?.[key] > newPriceTrend?.[key]) {
      return "item-after negative";
    }
    return "item-after";
  };

  // const debounced = useDebouncedCallback(async (value) => {
  //   setSearchValue(value);
  // }, 500);

  // const onSearchChange = (event: any) => {
  //   const value = event.target.value;
  //   setInputValue(value);
  //   debounced(value);
  // };

  const handleChange = (value: string) => {
    const filteredValue = value.replace(/[^0-9.]/g, "");
    if (filteredValue) {
      setFieldValue("newPrice", filteredValue);
      setDebounced(true);
      resetDebounced();
      if (activeTrend) {
        setPrevTrend(activeTrend);
      } else {
        setPrevTrend(newPriceTrend);
      }
      const range = chooseRangeTrends(datesOfGraphElasticity, value);
      if (range) {
        const calculatedTrend = calculateActiveTrend(
          range[0],
          range[1],
          +value
        );

        setActiveTrendState(calculatedTrend);
      }
    }
  };

  return (
    <div className="item-price-elasticity">
      <section className="block-elasticity ">
        <h3 className="elasticity-title">
          Price Elasticity
          <span>{titleTooltip}</span>
        </h3>
      </section>
      {isUpdating ? (
        <>
          <section>
            <CustomGraphElasticity
              data={datesOfGraphElasticity}
              newPriceValue={newPrice}
              currentPrice={currentPrice}
              setActiveTrend={memoizedSetActiveTrend}
            />
          </section>

          <section className="section-price">
            <div className="df ai-center">
              <span className="price-title">New price</span>
              <div className="df ai-center">
                <form
                  className="item-price-elasticity-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                  }}
                >
                  <InputWithIcons
                    classNameInput="item-price-elasticity-input"
                    value={values.newPrice || ""}
                    onChange={(value: string) => handleChange(value)}
                  />
                  <span className="item-price-elasticity-input-label">
                    Platform suggested price {newPrice}
                  </span>
                  <ButtonStyled
                    type="primary"
                    text="Save"
                    htmlType="submit"
                    fill="gray-primary-900"
                    className={`save-price-btn`}
                    disabled={newPrice === values.newPrice}
                  />
                </form>
              </div>
            </div>
          </section>
        </>
      ) : (
        <div className="empty-graph">
          <div className="empty-graph-content">
            <div>
              <Spiner />
            </div>
            <div className="empty-graph-title">Updating price elasticity</div>
            <div className="empty-graph-text">
              This process may take a few minutes to complete.
            </div>
          </div>
        </div>
      )}

      <section className="section-impact">
        <h3 className="impact-title">
          Price Change Impact (30-Day)
          <span>{titleTooltipImpact}</span>
        </h3>
        {isUpdating ? (
          <div className="impact-items">
            {optionImpact.map((el, ind) => {
              return (
                <div className="impact-item" key={ind}>
                  <div className="df jc-sb mb-8">
                    <div className="item-label">{el.label}</div>
                    <div
                      className={
                        el.profit.includes("-")
                          ? `item-profit-down`
                          : `item-profit-up`
                      }
                    >
                      {el.profit}
                    </div>
                  </div>

                  <div className="df jc-sb ai-center">
                    <div className="item-before">{el.valueBefore}</div>
                    <div className="item-arrow">
                      <Arrow />
                    </div>
                    <div
                      className={
                        isDebounced
                          ? chooseChangeValueClass(el.key)
                          : "item-after"
                      }
                    >
                      {el.valueAfter}
                      {isDebounced ? el?.onEvent && el?.onEvent() : null}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="empty-graph-forecast">
            <div className="empty-graph-content">
              <div>
                <Spiner />
              </div>
              <div className="empty-graph-title">Updating forecasts</div>
              <div className="empty-graph-text">
                This process may take a few minutes to complete.
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
});

export default ItemPriceElasticity;
