import { FC, useEffect, useState } from "react";
import "./itemMarket.scss";
import {
  BadgeCustom,
  CustomTooltip,
  MultipleSelect,
} from "@shared/ui/components";
import { ArrowBorder, Info } from "@shared/ui/assets";
import { Select, Switch } from "antd";
import CustomGraphLine from "../CustomGraphLine/CustomGraphLine";
import { generateTextColumn, TableCustom } from "@shared/common";
import {
  IItemCompetitor,
  Period,
  useItemInfoStore,
} from "@entities/itemDetails";
import ConfirmMonitoringPopup from "../ConfirmMonitoringPopup/ConfirmMonitoringPopup";
import moment from "moment";
import { Spiner } from "@features/spiner";
import { RepricingStatus, useProductsStore } from "@entities/products";
import { useGroupsStore } from "@entities/groups";
import { useParams } from "react-router-dom";

const optionsPeriod = [
  { value: Period.LAST_7_DAYS, label: "Last 7 days" },
  { value: Period.LAST_28_DAYS, label: "Last 28 days" },
];

const arrColors = ["#6610F2", "#D63384", "#FD7E14", "#0DCAF0", "#198754"];

interface IProps {
  productId: string;
}

const ItemMarket: FC<IProps> = (props) => {
  const { productId } = props;
  const { id } = useParams();
  const { itemCompetitors } = useItemInfoStore((state) => state);

  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [optionsCompetitors, setOptionCompetitors] = useState<any[]>([]);

  const {
    getItemCompetitors,
    changeMonitoringStatus,
    getDatesOfGraphCompetitors,
    datesOfGraphCompetitors,
    clearDatesOfGraphCompetitors,
    isLoading,
    changeLocalMonitoringStatus,
  } = useItemInfoStore((state) => state);
  const { getProductById, getCountNewPriceReady, setStatusProductById } =
    useGroupsStore((state) => state);
  const [isOpenConfirmMonitoringDialog, setOpenConfirmMonitoringDialog] =
    useState(false);
  const [activeCompetitorInfo, setActiveCompetitorInfo] = useState<any>(null);

  const [selectedPeriod, setSelectedPeriod] = useState<Period>(
    Period.LAST_28_DAYS
  );

  const customStringProductId = "customStringProductId";

  const compareNewDataArrFromNames = itemCompetitors
    ? itemCompetitors
        .map((el, ind) => ({
          id: el.competitorProductId,
          name: el.name,
          color: arrColors[ind],
        }))
        .concat([
          {
            id: customStringProductId,
            name: "yourCurrentPrice",
            color: "#000000",
          },
        ])
    : [];

  const transformCompetitorData = () => {
    if (!datesOfGraphCompetitors.length) return [];

    const result: any = [];

    const idToNameMap = Object.fromEntries(
      compareNewDataArrFromNames.map(({ id, name }) => [id, name])
    );

    const sortedDates = datesOfGraphCompetitors.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );

    const groupedByDate: Record<
      string,
      Record<string, string>
    > = sortedDates.reduce((acc: any, { price, date, competitorProductId }) => {
      const formattedDate = date.split("T")[0];
      if (!acc[formattedDate]) acc[formattedDate] = {};
      const actuallyId = competitorProductId || customStringProductId;

      if (actuallyId) {
        acc[formattedDate][idToNameMap[actuallyId]] = price;
      }

      return acc;
    }, {});

    for (const [date, prices] of Object.entries(groupedByDate)) {
      result.push({ date, ...prices });
    }

    return result;
  };

  const getDateToRequest = () => {
    const date28DaysFromNow = moment()
      .subtract(28, "days")
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format("YYYY-MM-DDTHH:mm:ss.SSS");
    const date7DaysFromNow = moment()
      .subtract(6, "days")
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format("YYYY-MM-DDTHH:mm:ss.SSS");
    const chooseDateToRequest =
      selectedPeriod === Period.LAST_28_DAYS
        ? date28DaysFromNow
        : date7DaysFromNow;
    return chooseDateToRequest;
  };

  const getItemCompetitorsLocal = async () => {
    const data = await getItemCompetitors(productId);
    return data;
  };

  useEffect(() => {
    clearDatesOfGraphCompetitors();
    const fetchData = async () => {
      const data: any = await getItemCompetitorsLocal();

      if (data) {
        const competitorsIds = data.map(
          (item: IItemCompetitor) => item.competitorProductId
        );
        await getDatesOfGraphCompetitors(
          getDateToRequest(),
          productId,
          competitorsIds
        );
      }
    };

    fetchData();
  }, [productId]);

  useEffect(() => {
    const optionsCompetitors = itemCompetitors.map((el) => {
      return {
        label: <>{el.name}</>,
        value: el.competitorProductId.toString(),
      };
    });

    setOptionCompetitors(optionsCompetitors);

    const initialSelectedItems = optionsCompetitors.map(
      (option) => option.value
    );
    setSelectedItems(initialSelectedItems);
  }, [itemCompetitors, productId]);

  const historyTooltip = (
    <CustomTooltip
      title={"View competitors’ pricing history data."}
      placement="right"
    >
      <Info />
    </CustomTooltip>
  );

  const competitorsTooltip = (
    <CustomTooltip
      title={"View key competitors’ data for today."}
      placement="right"
    >
      <Info />
    </CustomTooltip>
  );

  const handleChooseCompetitors = (values: string[]) => {
    clearDatesOfGraphCompetitors();
    setSelectedItems(values);
    getDatesOfGraphCompetitors(getDateToRequest(), productId, values);
  };

  const handleChangePeriod = (value: Period) => {
    clearDatesOfGraphCompetitors();
    const date28DaysFromNow = moment()
      .subtract(28, "days")
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format("YYYY-MM-DDTHH:mm:ss.SSS");
    const date7DaysFromNow = moment()
      .subtract(6, "days")
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format("YYYY-MM-DDTHH:mm:ss.SSS");
    const chooseDateToRequest =
      value === Period.LAST_28_DAYS ? date28DaysFromNow : date7DaysFromNow;
    setSelectedPeriod(value);
    getDatesOfGraphCompetitors(chooseDateToRequest, productId, selectedItems);
  };

  const headDataTable = [
    {
      content: generateTextColumn({
        text: "Name",
        isSorted: false,
        sortDirection: "",
        isActiveSort: false,
      }),
      className: "table-w-140",
    },
    {
      content: generateTextColumn({
        text: "Traffic",
        isSorted: false,
        sortDirection: "",
        isActiveSort: false,
        tooltipText: "Website traffic per month",
      }),
      // className: "table-w-71",
    },
    {
      content: generateTextColumn({
        text: "Delivery",
        tooltipText: "Expected delivery time for competitor product item",
      }),
      // className: "table-w-109",
    },
    {
      content: generateTextColumn({
        text: "Price",
        isSorted: false,
        sortDirection: "",
        isActiveSort: false,
      }),
      // className: "table-w-109",
    },
    {
      content: generateTextColumn({
        text: "Monitoring",
        tooltipText: "Competitor price monitoring",
      }),
      className: "table-w-120",
    },
  ];

  const dataTableBodyCompare = [
    {
      content: ({ name }: IItemCompetitor, ind: number) => (
        <div className="name-block-with-color">
          <div
            className="colorCircle"
            style={{ backgroundColor: arrColors[ind] }}
          ></div>{" "}
          {name}
        </div>
      ),
      className: "",
    },
    {
      content: ({ competitorProductTraffic }: IItemCompetitor) => (
        <div>{competitorProductTraffic}</div>
      ),
      className: "ta-right",
    },
    {
      content: ({ competitorProductDelivery }: IItemCompetitor) => {
        const isOutOfStock =
          competitorProductDelivery.toLowerCase() === "out of stock";
        return isOutOfStock ? (
          <BadgeCustom
            text={competitorProductDelivery}
            className="badge-custom"
          />
        ) : (
          <div>{competitorProductDelivery}</div>
        );
      },
      className: "table-w-120 deliveryColumn",
    },
    {
      content: ({ competitorProductPrice }: IItemCompetitor) => (
        <div>${competitorProductPrice}</div>
      ),
      className: "",
    },
    {
      content: ({ monitoringStatus, id, name }: IItemCompetitor) => (
        <div className="df ai-center jc-c">
          <CustomTooltip
            title={
              "Competitor price monitoring adjustments are available in Competition Strategy."
            }
            placement="top"
          >
            <Switch
              onChange={() => {
                setActiveCompetitorInfo({ monitoringStatus, id, name });
                setOpenConfirmMonitoringDialog(true);
              }}
              checked={monitoringStatus}
            />
          </CustomTooltip>
        </div>
      ),
      className: "",
    },
  ];

  const dataTableBody = itemCompetitors.map((item, ind) => {
    return dataTableBodyCompare.map((el) => ({
      element: el.content(item, ind),
      className: el.className,
      item: item,
    }));
  });

  const data = transformCompetitorData();

  const handleOk = async () => {
    setStatusProductById(productId, RepricingStatus.PENDING);
    changeLocalMonitoringStatus(
      activeCompetitorInfo.id,
      !activeCompetitorInfo.monitoringStatus
    );
    setOpenConfirmMonitoringDialog(false);

    await changeMonitoringStatus(
      activeCompetitorInfo.id,
      !activeCompetitorInfo.monitoringStatus
    );
    await getProductById(productId);
    await getCountNewPriceReady(id);
  };

  return (
    <>
      <ConfirmMonitoringPopup
        isOpenModal={isOpenConfirmMonitoringDialog}
        onClose={() => setOpenConfirmMonitoringDialog(false)}
        competitorName={activeCompetitorInfo?.name || ""}
        onOk={handleOk}
      />
      <div className="item-market">
        <section className="item-market-history ">
          <h3 className="market-title">
            Price History <span>{historyTooltip}</span>
          </h3>
        </section>
        <section>
          <div className="df">
            <div className="market-select-competitors">
              <span className="market-select-competitors-title">
                Competitors
              </span>{" "}
              <div className="market-select-competitors-block">
                <MultipleSelect
                  options={optionsCompetitors}
                  onChange={handleChooseCompetitors}
                  selectedItems={selectedItems}
                />
              </div>
            </div>
            <div className="market-select-period">
              <span className="market-select-period-title">Period</span>{" "}
              <Select
                value={selectedPeriod}
                options={optionsPeriod}
                placeholder="Select period"
                className="custom-arrow"
                suffixIcon={<ArrowBorder color="#212527" />}
                onChange={(value) => {
                  handleChangePeriod(value);
                }}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          </div>
        </section>
        {!isLoading ? (
          <section className="item-market-graph">
            <CustomGraphLine
              data={data}
              mode={selectedPeriod}
              dataSettings={compareNewDataArrFromNames}
              dataOriginal={datesOfGraphCompetitors}
            />
          </section>
        ) : (
          <div className="item-market-graph-loader">
            <Spiner />
          </div>
        )}
        <section className="market-competitors">
          <h3 className="market-title-competitors">
            Competitors <span>{competitorsTooltip}</span>
          </h3>
          <div className="market-competitors-table">
            <TableCustom headData={headDataTable} bodyData={dataTableBody} />
          </div>
        </section>
      </div>
    </>
  );
};

export default ItemMarket;
