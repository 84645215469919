import { io, Socket } from "socket.io-client";

const token = localStorage.getItem("_accessToken");

const url = `${process.env.REACT_APP_WS_URL}?token=${token}`;

const options = {
  autoConnect: true,
  transports: ["websocket"],
};

const socket: Socket = io(url, options);

export default socket;
