import { IDataTransfer, IQuery } from "@shared/interfaces";

export enum RepricingStatus {
  NEW = "NEW",
  PENDING = "PENDING",
  DONE = "DONE",
}

export interface IProduct {
  id: string;
  cost: string;
  name: string;
  store: string;
  brand: string;
  article: string;
  imageUrl: string;
  category: string;
  subcategory: string;
  isSelected: boolean;
  currentPrice: string;
  currentMargin: string;
  exceptionLimits?: any;
  created_at: string;
  state: boolean;
  updated_at?: string;
  externalLink?: string;
  newPrice?: string;
  newMargin?: string;
  last_reprice?: string;
  isRepriced?: boolean;
  status: RepricingStatus;
}

export interface IProductState {
  products: IProduct[];
  productsSelectedIds: string[];
  recordsCount: number;
  getProducts: (params: IDataTransfer) => void;
  setProducts: (arr: IProduct[], recordsCount: number) => void;
  selectAllProducts: (flag: boolean) => void;
  setProductsSelectedIds: (arr: string[]) => void;
  selectProduct: (productId: string, flag: boolean) => void;
}
