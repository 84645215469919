import { ArrowBorder, Close, Delete, Plus } from "@shared/ui/assets";
import { ButtonStyled, InputCustom } from "@shared/ui/components";
import { Divider, Modal, Select } from "antd";
import { FC } from "react";
import "./inviteUsersPopup.scss";
import { FormikErrors, FormikTouched, useFormik } from "formik";
import {
  capitalizeFirstLetter,
  generateId,
  getParamsAsObject,
} from "@shared/utils";
import {
  IInviteUser,
  UserRolesEnum,
  useSettingsStore,
} from "@entities/settings";
import { validationSchema } from "./validation";
import { useSearchParams } from "react-router-dom";

// if (Array.isArray(errors?.items)) {
//   const errorItems = errors.items as FormikErrors<FormItem>[]; // Приведение к массиву
//   newErrors = errorItems.filter(
//     (_, i) => values.items[i].id !== removedItemId
//   );
// }
interface IProps {
  flag: boolean;
  onClose: () => void;
}

const optionsSelectRoles = [
  {
    label: capitalizeFirstLetter(UserRolesEnum.ADMIN),
    value: UserRolesEnum.ADMIN,
  },
  {
    label: capitalizeFirstLetter(UserRolesEnum.EDITOR),
    value: UserRolesEnum.EDITOR,
  },
  // {
  //   label: capitalizeFirstLetter(UserRolesEnum.VIEWER),
  //   value: UserRolesEnum.VIEWER,
  // },
];

const initialValue = {
  email: "",
  role: UserRolesEnum.EDITOR,
};

const InviteUsersPopup: FC<IProps> = (props) => {
  const { flag, onClose } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const { saveInviteUser, inviteUsersErrors, getUsersTable } = useSettingsStore(
    (state) => state
  );

  const initialValues: IInviteUser = initialValue;
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const res = await saveInviteUser(values);
      getUsersTable(getParamsAsObject(searchParams));
      onClose();
      resetForm();
    },
  });

  const onCloseLocal = () => {
    onClose();
    resetForm();
  };

  const { setFieldValue, values, errors, resetForm } = formik;

  const handleChangeEmail = (e: any) => {
    const value = e.target.value;
    setFieldValue("email", value);
  };

  const handleChangeSelect = (value: any) => {
    setFieldValue("role", value);
  };

  return (
    <Modal
      open={flag}
      footer={null}
      maskClosable={false}
      closeIcon={false}
      centered={true}
      onCancel={() => onCloseLocal()}
      className="users-modal-invite"
    >
      <>
        <div className="users-modal-invite__wrapper">
          <div onClick={onCloseLocal} className="modal-close__icon">
            <Close />
          </div>
          <h3>Invite Users</h3>
        </div>
        <Divider />
      </>
      <form
        onSubmit={(e) => {
          formik.handleSubmit();
          e.preventDefault();
        }}
      >
        <div className="modal__invite-user-items">
          <div className="modal__invite-user-content">
            <div className="modal__invite-user-form-block">
              <div className="modal__invite-user-email-block">
                <div className="user-label">Email</div>
                <div className="user-input">
                  <InputCustom
                    value={values.email}
                    onChangeInput={(e) => handleChangeEmail(e)}
                    type="text"
                    name="name"
                    className={errors.email ? "error-validation-input" : ""}
                  />
                  {errors.email && (
                    <span className="validation-error">{errors.email}</span>
                  )}
                </div>
              </div>
              <div className="modal__invite-user-role-block ">
                <div className="role-label">Role</div>
                <div className="role-select">
                  <Select
                    value={values.role}
                    options={optionsSelectRoles}
                    className="custom-arrow"
                    suffixIcon={<ArrowBorder color="#212527" />}
                    onChange={(value) => handleChangeSelect(value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Divider />

        <div className="modal__monitoring-footer">
          <div className="df jc-fe">
            <ButtonStyled
              text="Cancel"
              htmlType="button"
              className="w-84 mr-16"
              onClick={onCloseLocal}
            />
            {/* //Send invite */}

            <ButtonStyled
              type="primary"
              text="Send invite"
              htmlType="submit"
              fill="gray-primary-900"
              className="w-110"
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default InviteUsersPopup;
