import React, { FC } from "react";
import "./loaderDefault.scss";
import { Spiner } from "@features/spiner";

interface IProps {
  className?: string;
}

const LoaderDefault: FC<IProps> = (props) => {
  const { className } = props;
  return (
    <div className={"loader-block-main " + className}>
      <Spiner />
    </div>
  );
};

export default LoaderDefault;
