import { ReactNode } from "react";

export interface ICustomNotification {
  condition: boolean;
  text: string;
}

export interface IFilterItem {
  name: string;
  value: string;
  element?: React.ReactElement;
  className?: string;
}

export enum SortParams {
  ASC = "ASC",
  DESC = "DESC",
}
export type ISortParams = SortParams.ASC | SortParams.DESC;

export interface IThead {
  className?: string;
  content: ReactNode | null;
  sortKey?: string;
  handleSort?: (key: string) => void;
}

export interface ITableRowData {
  element: ReactNode;
  isSelected?: boolean;
  className?: () => string | string;
  item?: any;
  isExpanded?: boolean;
}
