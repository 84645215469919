import { Close } from "@shared/ui/assets";
import { ButtonStyled } from "@shared/ui/components";
import { Divider, Modal } from "antd";
import { FC } from "react";
import "./removeUserPopup.scss";

interface IProps {
  flag: boolean;
  onClose: () => void;
  onOk: () => void;
  activeUser: any;
}

const RemoveUserPopup: FC<IProps> = (props) => {
  const { flag, onClose, onOk, activeUser } = props;

  return (
    <Modal
      open={flag}
      onOk={onOk}
      footer={null}
      maskClosable={false}
      closeIcon={false}
      centered={true}
      onCancel={() => onClose()}
    >
      <>
        <div className="users-modal-del__wrapper">
          <div onClick={onClose} className="modal-close__icon">
            <Close />
          </div>
          <h3>Delete User</h3>
        </div>
        <Divider />
      </>
      <div className="modal__remove-user-content">
        This will delete {activeUser?.name || ""} from team. You cannot undo
        this action.
      </div>
      <Divider />

      <div className="modal__monitoring-footer">
        <div className="df jc-fe">
          <ButtonStyled
            text="Cancel"
            htmlType="button"
            className="w-84 mr-16"
            onClick={onClose}
          />

          <ButtonStyled
            text="Delete"
            type="primary"
            fill="red-600"
            htmlType="button"
            className="w-84"
            onClick={onOk}
          />
        </div>
      </div>
    </Modal>
  );
};

export default RemoveUserPopup;
