import { FC } from "react";
import Intercom from "@intercom/messenger-js-sdk";

interface IProps {
  userId: string;
  name: string;
  email: string;
}
const IntercomConnectorWidget: FC<IProps> = (props) => {
  const { userId, name, email } = props;
  const createdAt = Math.floor(Date.now() / 1000);
  //   if(false){
  //     Intercom.shutdown();
  //   }

  Intercom({
    app_id: process.env.REACT_APP_INTERCOM_APP_ID || "",
    user_id: userId,
    name: name,
    email: email,
    created_at: createdAt,
    // custom_launcher_selector: "#my-custom-launcher",
  });
  return <></>;
};

export default IntercomConnectorWidget;
