import React, { FC, useState } from "react";
import "./switcherCustom.scss";

interface IProps {
  data: {
    key: string;
    content: JSX.Element;
  }[];
  onClick: (key: string) => void;
  activeIndex: number;
}

const SwitcherCustom: FC<IProps> = (props) => {
  const { data, onClick, activeIndex } = props;

  const handleClick = (key: string) => {
    onClick(key);
  };
  return (
    <div className="switcher-custom-_wrapper">
      {data.map((el, index) => {
        return (
          <div
            key={index}
            onClick={() => handleClick(el.key)}
            className={`switcher-custom__item  ${
              data[activeIndex].key === el.key ? "switcher-active" : ""
            }`}
          >
            {el.content}
          </div>
        );
      })}
    </div>
  );
};

export default SwitcherCustom;
