import { ArrowBorder, Close } from "@shared/ui/assets";
import { ButtonStyled, InputCustom } from "@shared/ui/components";
import { Divider, Modal, Select } from "antd";
import { FC, useEffect, useState } from "react";
import "./editUserPopup.scss";
import { UserRolesEnum } from "@entities/settings";
import { capitalizeFirstLetter } from "@shared/utils";

interface IProps {
  flag: boolean;
  onClose: () => void;
  onOk: (value: UserRolesEnum) => void;
  activeUser: any;
}
const optionsSelectRoles = [
  {
    label: capitalizeFirstLetter(UserRolesEnum.ADMIN),
    value: UserRolesEnum.ADMIN,
  },
  {
    label: capitalizeFirstLetter(UserRolesEnum.EDITOR),
    value: UserRolesEnum.EDITOR,
  },
  // {
  //   label: capitalizeFirstLetter(UserRolesEnum.VIEWER),
  //   value: UserRolesEnum.VIEWER,
  // },
];

const EditUserPopup: FC<IProps> = (props) => {
  const { flag, onClose, onOk, activeUser } = props;
  const [selectedItem, setSelectedItem] = useState<UserRolesEnum | null>(
    optionsSelectRoles[1].value
  );

  const onCloseLocal = () => {
    setSelectedItem(optionsSelectRoles[1].value);
    onClose();
  };

  return (
    <Modal
      open={flag}
      //   onOk={onOk}
      footer={null}
      maskClosable={false}
      closeIcon={false}
      centered={true}
      onCancel={onCloseLocal}
    >
      <>
        <div className="users-modal-edit__wrapper">
          <div onClick={onCloseLocal} className="modal-close__icon">
            <Close />
          </div>
          <h3>Edit Role</h3>
        </div>
        <Divider />
      </>
      <div className="modal__edit-user-content">
        <div className="edit_body">
          <div className="edit-user-block">
            <div className="user-label">User</div>
            <div className="user-name">
              {activeUser?.firstName} {activeUser?.lastName}
            </div>
          </div>
          <div className="edit-role-block">
            <div className="role-label">Role</div>
            <div className="role-select">
              <Select
                value={selectedItem}
                options={optionsSelectRoles}
                className="custom-arrow"
                suffixIcon={<ArrowBorder color="#212527" />}
                onChange={(value) => setSelectedItem(value)}
              />
            </div>
          </div>
        </div>
      </div>
      <Divider />

      <div className="modal__monitoring-footer">
        <div className="df jc-fe">
          <ButtonStyled
            text="Cancel"
            htmlType="button"
            className="w-84 mr-16"
            onClick={onCloseLocal}
          />

          <ButtonStyled
            type="primary"
            text="Save"
            htmlType="button"
            fill="gray-primary-900"
            className="w-84"
            onClick={() => {
              selectedItem && onOk(selectedItem);
              onCloseLocal();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default EditUserPopup;
