import { useGroupsStore } from "@entities/groups";
import {
  generateTextColumn,
  getMenuPropsItems,
  Pagination,
  TableCustom,
} from "@shared/common";
import { ISortParams, SortParams } from "@shared/interfaces";
import { BadgeCustom } from "@shared/ui/components";
import { Divider, MenuProps } from "antd";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { UserRolesEnum, useSettingsStore } from "@entities/settings";
import { AsideDropdown } from "@widgets/admin/asideDropdown";
import { Dots } from "@shared/ui/assets";
import RemoveUserPopup from "./RemoveUserPopup/RemoveUserPopup";
import EditUserPopup from "./EditUserPopup/EditUserPopup";
import {
  DIRECTION_PARAM,
  OFFSET_PARAM,
  ORDER_BY_PARAM,
} from "@shared/constants";
import moment from "moment";
import "./tableUsers.scss";
import { getParamsAsObject } from "@shared/utils";
import { useSearchParams } from "react-router-dom";

type MenuItem = Required<MenuProps>["items"][number];
interface IProps {
  setSortConfig: (config: {
    key: string;
    direction: ISortParams | null;
  }) => void;
  sortConfig: { key: string; direction: ISortParams | null };
  setCurrentLimit: (limit: number) => void;
  setCurrentOffset: (offset: number) => void;
  currentOffset: number;
  currentLimit: number;
  updateParams: (
    params: Record<string, string | number | null | undefined>
  ) => void;
}

const THEAD_SORT_KEYS = {
  NAME: "fullname",
  EMAIL: "email",
  ROLE: "role",
  LAST_ACTIVITY: "last_activity",
  JOINED: "joined",
};

const TableUsers: FC<IProps> = ({
  setSortConfig,
  sortConfig,
  setCurrentLimit,
  setCurrentOffset,
  currentOffset,
  currentLimit,
  updateParams,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { usersTable, deleteUserById, editUserById, getUsersTable } =
    useSettingsStore((state) => state);

  const recordsCount = useGroupsStore((state) => state.recordsCountInGroup);
  const productsInGroup = useGroupsStore((state) => state.productsInGroup);

  const [isEditRolePopupShow, setEditRolePopupShow] = useState(false);
  const [isDeleteUserPopupShow, setDeleteUserPopupShow] = useState(false);
  const [activeUser, setActiveUser] = useState<any>();

  const itemsActionMenu: MenuItem[] = useMemo(
    () => [
      getMenuPropsItems(
        "Edit role",
        "rename",
        null,
        undefined,
        false,
        false,
        () => setEditRolePopupShow(true)
      ),
      getMenuPropsItems(
        "Delete user",
        "delete",
        null,
        undefined,
        false,
        true,
        () => setDeleteUserPopupShow(true)
      ),
    ],
    []
  );

  const handleSort = (key: string) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === SortParams.ASC
        ? SortParams.DESC
        : SortParams.ASC;
    setSortConfig({ key, direction });
    updateParams({
      [ORDER_BY_PARAM]: key,
      [DIRECTION_PARAM]:
        direction === SortParams.ASC ? SortParams.ASC : SortParams.DESC,
    });
  };

  const definePageCount = useCallback(() => recordsCount, [recordsCount]);

  const definePrefPage = useCallback(() => {
    const newOffset = Math.max(0, currentOffset - currentLimit);
    setCurrentOffset(newOffset);
  }, [currentOffset, currentLimit, setCurrentOffset]);

  const defineNextPage = useCallback(() => {
    const newOffset = currentOffset + currentLimit;
    if (newOffset < recordsCount) {
      setCurrentOffset(newOffset);
    }
  }, [currentOffset, currentLimit, recordsCount, setCurrentOffset]);

  const onChangeItemsCount = useCallback(
    (value: string) => {
      const limit = Number(value);
      setCurrentLimit(limit);
      setCurrentOffset(0);
    },
    [setCurrentLimit, setCurrentOffset]
  );

  const defineCurrentOffset = useCallback(() => {
    return productsInGroup.length && currentOffset >= 0
      ? (currentOffset + 1).toString()
      : "0";
  }, [productsInGroup, currentOffset]);

  const itemsMenuPending: MenuItem[] = [
    getMenuPropsItems(
      "Cancel invite",
      "cancel",
      null,
      undefined,
      false,
      true,
      (e) => alert("Cancel invite")
    ),
  ];

  const dataTableBodyCompare = [
    {
      content: ({ firstName, lastName }: any) => {
        return (
          <div>
            {firstName} {lastName}
          </div>
        );
      },
      className: () => ``,
    },
    {
      content: ({ email }: any) => {
        return <div>{email}</div>;
      },
      className: () => ``,
    },
    {
      content: ({ role }: any) => {
        const chooseStyleBadge = () => {
          switch (role.toLowerCase()) {
            case UserRolesEnum.ADMIN.toLowerCase():
              return "badge-role_admin";
            case UserRolesEnum.EDITOR.toLowerCase():
              return "badge-role_editor";
            case UserRolesEnum.VIEWER.toLowerCase():
              return "badge-role_viewer";
            default:
              return "badge-role_viewer";
          }
        };
        return (
          <div>
            <BadgeCustom text={role} className={chooseStyleBadge()} />
          </div>
        );
      },
      className: () => ``,
    },
    {
      content: ({ updated_at }: any) => {
        return <div>{moment(updated_at).format("DD.MM.YYYY")}</div>;
      },
      className: () => ``,
    },
    {
      content: ({ joined, inviteToken, updated_at }: any) => {
        return (
          <div>
            {inviteToken ? (
              <BadgeCustom text="Pending" className="badge-role_pending" />
            ) : (
              moment(updated_at).format("DD.MM.YYYY")
            )}
          </div>
        );
      },
      className: () => ``,
    },
    {
      content: (user: any) => {
        const isPending = user.status === "Pending";
        return (
          <div className="ta-right" onClick={() => setActiveUser(user)}>
            <AsideDropdown
              onOpenChange={(isOpen) => {
                if (!isOpen) {
                  // clearGroup();
                }
              }}
              collapsed={true}
              items={isPending ? itemsMenuPending : itemsActionMenu}
              placement="bottomRight"
              className="relu__menu"
            >
              <div>
                <Dots color="#6C757D" />
              </div>
            </AsideDropdown>
          </div>
        );
      },
      className: () => ``,
    },
  ];

  const headDataTable = [
    {
      content: generateTextColumn({
        text: "Name",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.NAME === sortConfig.key,
      }),
      className: "table-w-326",
      sortKey: THEAD_SORT_KEYS.NAME,
    },
    {
      content: generateTextColumn({
        text: "Email",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.EMAIL === sortConfig.key,
      }),
      className: "table-w-230",
      sortKey: THEAD_SORT_KEYS.EMAIL,
    },
    {
      content: generateTextColumn({
        text: "Role",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.ROLE === sortConfig.key,
      }),
      className: "table-w-151 ",
      sortKey: THEAD_SORT_KEYS.ROLE,
    },
    {
      content: generateTextColumn({
        text: "Last activity",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.LAST_ACTIVITY === sortConfig.key,
      }),
      className: "table-w-161 ",
      sortKey: THEAD_SORT_KEYS.LAST_ACTIVITY,
    },
    {
      content: generateTextColumn({
        text: "Joined",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.JOINED === sortConfig.key,
      }),
      className: "table-w-161 ",
      sortKey: THEAD_SORT_KEYS.JOINED,
    },
    {
      content: generateTextColumn({
        text: "Actions",
      }),
      className: "table-w-90 ",
    },
  ];

  const dataTableBody = usersTable.map((item) => {
    return dataTableBodyCompare.map((el) => ({
      element: el.content(item),
      //   isSelected: item.id === activeProductId,
      className: "",
      item: item,
    }));
  });

  const handleOkDeleteUser = async () => {
    await deleteUserById(activeUser.id);
    setDeleteUserPopupShow(false);
    getUsersTable(getParamsAsObject(searchParams));
  };

  const handleOkEditUser = async (value: UserRolesEnum) => {
    await editUserById(activeUser.id, value);
    setEditRolePopupShow(false);
    getUsersTable(getParamsAsObject(searchParams));
  };

  return (
    <>
      <RemoveUserPopup
        flag={isDeleteUserPopupShow}
        onClose={() => setDeleteUserPopupShow(false)}
        onOk={handleOkDeleteUser}
        activeUser={activeUser}
      />
      <EditUserPopup
        flag={isEditRolePopupShow}
        onClose={() => setEditRolePopupShow(false)}
        onOk={handleOkEditUser}
        activeUser={activeUser}
      />
      <div ref={containerRef}>
        <TableCustom
          headData={headDataTable}
          bodyData={dataTableBody}
          handleSort={handleSort}
        />
      </div>
      <div className="pagination__container group-items__pagination-container">
        <div></div>
        <Pagination
          prevPage={definePrefPage}
          nextPage={defineNextPage}
          onChange={onChangeItemsCount}
          recordsCount={definePageCount()}
          currentLimit={currentLimit.toString()}
          currentOffset={defineCurrentOffset()}
          currentSelectLimit={currentLimit.toString()}
        />
      </div>
    </>
  );
};

export default TableUsers;
