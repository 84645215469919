import { useGroupsStore } from "@entities/groups";
import { IProduct } from "@entities/products";
import { generateTextColumn, Pagination, TableCustom } from "@shared/common";
import { ISortParams, SortParams } from "@shared/interfaces";
import Lock from "@shared/ui/assets/Lock";
import Unlock from "@shared/ui/assets/Unlock";
import { checkIsPositiveValue, findRangePercentage } from "@shared/utils";
import { Divider } from "antd";
import moment from "moment";
import React, { FC, useEffect, useRef, useState } from "react";
import "./tableItems.scss";

interface IProps {
  setSortConfig: any;
  tableWidth: number;
  setShowInfoDrawer: any;
  setActiveProductId: any;
  sortConfig: any;
  activeProductId: string;
  currentBrand: string[];
  setCurrentLimit: any;
  setCurrentOffset: any;
  currentOffset: number;
  currentLimit: number;
}

enum THEAD_SORT_KEYS {
  NAME = "name",
  COST = "cost",
  BRAND = "brand",
  STORE = "store",
  STATE = "state",
  SUBCATEGORY = "subcategory",
  LAST_REPRICE = "last_reprice",
  CURRENT_PRICE = "currentPrice",
  CURRENT_MARGIN = "currentMargin",
  NEW_PRICE = "newPrice",
  NEW_MARGIN = "newMargin",
}

const TableItems: FC<IProps> = (props) => {
  const {
    setSortConfig,
    tableWidth,
    setShowInfoDrawer,
    setActiveProductId,
    sortConfig,
    activeProductId,
    currentBrand,
    setCurrentLimit,
    setCurrentOffset,
    currentOffset,
    currentLimit,
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);

  const [widthOfScroll, setWidthOfScroll] = useState(0);
  const [isScrollable, setIsScrollable] = useState(false);
  const recordsCount = useGroupsStore((state) => state.recordsCountInGroup);

  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, scrollLeft: 0 });
  const { fetchChangeLockProductMode, isLoading, changeCountNewPriceReady } =
    useGroupsStore((state) => state);
  const productsInGroup = useGroupsStore((state) => state.productsInGroup);

  useEffect(() => {
    checkIfScrollable();
  }, [containerRef?.current, containerRef, tableWidth]);

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  const handleClickOnLock = (productId: string, isLocked: boolean) => {
    fetchChangeLockProductMode(productId, isLocked);
    changeCountNewPriceReady(isLocked, productId);
  };

  const showItemInfoDrawer = (productId: string) => {
    setShowInfoDrawer(true);
    setActiveProductId(productId);
  };

  const handleSort = (key: string) => {
    let direction: ISortParams = SortParams.ASC;
    if (sortConfig.key === key) {
      direction =
        sortConfig.direction === SortParams.ASC
          ? SortParams.DESC
          : SortParams.ASC;
    }
    setSortConfig({ key, direction });
  };

  const definePageCount = () => {
    return recordsCount;
  };

  const defineCurrentLimit = () => {
    const nextOffset = currentOffset + currentLimit;
    return (nextOffset > recordsCount ? recordsCount : nextOffset).toString();
  };

  const definePrefPage = () => {
    const newOffset = currentOffset - currentLimit;
    setCurrentOffset(newOffset < 0 ? 0 : newOffset);
  };

  const defineNextPage = (offset: string) => {
    const newOffset = currentOffset + currentLimit;
    if (newOffset < recordsCount) {
      setCurrentOffset(newOffset);
    }
  };

  const onChangeItemsCount = (value: string) => {
    setCurrentLimit(Number(value));
    setCurrentOffset(0);
  };

  const defineCurrentOffset = () => {
    const res =
      productsInGroup.length && currentOffset >= 0 ? currentOffset + 1 : 0;
    return res.toString();
  };

  const handleMouseDown = (e: any) => {
    e.preventDefault();

    setIsDragging(true);
    setOffset({
      x: e.clientX,
      scrollLeft: containerRef?.current?.scrollLeft || 0,
    });
  };

  const handleMouseMove = (e: any) => {
    e.preventDefault();
    if (!isDragging || !containerRef.current) return;

    const container = containerRef.current;
    const deltaX = e.clientX - offset.x;

    const scrollDistance =
      (deltaX / container.offsetWidth) * container.scrollWidth;

    container.scrollLeft = offset.scrollLeft + scrollDistance;

    setOffset({ x: e.clientX, scrollLeft: container.scrollLeft });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const headDataTable = [
    // {
    //   content: (
    //     <Checkbox
    //       checked={isAllSelected && !!productsInGroup.length}
    //       onChange={(event) => {
    //         selectAllProductsInGroup(event.target.checked);
    //       }}
    //     ></Checkbox>
    //   ),
    //   className: "w-48",
    // },
    {
      content: generateTextColumn({
        text: "Title & SKU",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.NAME === sortConfig.key,
      }),
      className: "table-w-380 sticky-table__th--left-name",
      sortKey: THEAD_SORT_KEYS.NAME,
    },
    {
      content: generateTextColumn({ text: "Image" }),
      className: "table-w-71 sticky-table__th--left-image",
    },
    {
      content: generateTextColumn({
        text: "Lock",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.STATE === sortConfig.key,
        tooltipText: "Lock current price from repricing",
      }),
      className: "table-w-109",
      sortKey: THEAD_SORT_KEYS.STATE,
    },
    {
      content: generateTextColumn({
        text: "Current price",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.CURRENT_PRICE === sortConfig.key,
      }),
      className: "table-w-130",
      sortKey: THEAD_SORT_KEYS.CURRENT_PRICE,
    },
    {
      content: generateTextColumn({
        text: "Current margin",
        //TODO
        isSorted: true,
        sortDirection: sortConfig.direction,
        isActiveSort: THEAD_SORT_KEYS.CURRENT_MARGIN === sortConfig.key,
      }),
      className: "table-w-141",
      sortKey: THEAD_SORT_KEYS.CURRENT_MARGIN,
    },
    {
      content: generateTextColumn({
        text: "New price",
        tooltipText: "New price after repricing",
        //TODO
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.NEW_PRICE === sortConfig.key,
      }),
      className: "table-w-133",
      sortKey: THEAD_SORT_KEYS.NEW_PRICE,
    },
    {
      content: generateTextColumn({
        text: "New margin",
        tooltipText: "Expected margin after repricing",
        //TODO
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.NEW_MARGIN === sortConfig.key,
      }),
      className: "table-w-144",
      sortKey: THEAD_SORT_KEYS.NEW_MARGIN,
    },
    {
      content: generateTextColumn({
        text: "Cost",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.COST === sortConfig.key,
      }),
      className: "table-w-90",
      sortKey: THEAD_SORT_KEYS.COST,
    },
    {
      content: generateTextColumn({
        text: "Brand",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.BRAND === sortConfig.key,
      }),
      className: "table-w-120 brand-w-style",
      sortKey: THEAD_SORT_KEYS.BRAND,
    },
    {
      content: generateTextColumn({
        text: "Store",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.STORE === sortConfig.key,
      }),
      className: "table-w-95 store-w-style",
      sortKey: THEAD_SORT_KEYS.STORE,
    },
    {
      content: generateTextColumn({
        text: "Last reprice",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.LAST_REPRICE === sortConfig.key,
      }),
      className: "table-w-161",

      sortKey: THEAD_SORT_KEYS.LAST_REPRICE,
    },
  ];

  const dataTableBodyCompare = [
    // TODO
    // {
    //   content: ({ isSelected, id }: IProduct) => (
    //     <Checkbox
    //       checked={productsInGroupSelectedIds.some((el) => el === id)}
    //       onChange={(event) => {
    //         selectProductInGroup(id, event?.target.checked);
    //       }}
    //     ></Checkbox>
    //   ),
    // },
    {
      content: ({ id, article, name }: IProduct) => {
        return (
          <div
            className="default-padding-blocks-table custom-height-group-items-name"
            onClick={(e) => {
              onOpenDrover(id, e);
            }}
          >
            <h5 className="product__subtitle ">{article}</h5>
            <h5
              className="group_product__title"
              style={{ width: tableWidth * 0.3 }}
            >
              {name}
            </h5>
          </div>
        );
      },
      className: ({ id }: IProduct) =>
        `table-w-380 sticky-table__body--left-name  ${
          id === activeProductId ? "active-row-sticky" : ""
        }`,
    },
    {
      content: ({ imageUrl, id }: IProduct) => {
        return (
          <div
            className="image-block-table custom-height-group-items"
            onClick={(e) => {
              onOpenDrover(id, e);
            }}
          >
            <img alt="img-url" className="product__img" src={imageUrl} />
          </div>
        );
      },
      className: ({ id }: IProduct) =>
        `table-w-71 sticky-table__body--left-image ${
          id === activeProductId ? "active-row-sticky" : ""
        }`,
    },
    {
      content: ({ state, id }: IProduct) => (
        <div
          className="default-padding-blocks-table"
          onClick={(e) => {
            handleClickOnLock(id, !state);
            e.stopPropagation();
          }}
        >
          {state ? <Lock /> : <Unlock />}
        </div>
      ),
      className: () => "table-w-109 ",
    },
    {
      content: ({ currentPrice, id }: IProduct) => (
        <h5
          className="default-padding-blocks-table ta-right custom-height-group-items"
          onClick={(e) => {
            onOpenDrover(id, e);
          }}
        >
          ${currentPrice}
        </h5>
      ),
      className: ({ id, isRepriced }: IProduct) => {
        return `table-w-109 ${isRepriced ? "complete-current-price" : ""}`;
      },
    },
    {
      content: ({ currentMargin = "0", id }: IProduct) => (
        <h5
          onClick={(e) => {
            onOpenDrover(id, e);
          }}
          className="ta-right default-padding-blocks-table custom-height-group-items"
        >
          {currentMargin}%
        </h5>
      ),
      className: () => "ta-right table-w-109",
    },
    {
      content: ({ state, id, newPrice, currentPrice }: IProduct) => {
        const newPriceChooser = newPrice ? +newPrice : null;
        const range = findRangePercentage(+currentPrice, newPriceChooser);
        return (
          <h5
            onClick={(e) => {
              onOpenDrover(id, e);
            }}
            className="ta-right default-padding-blocks-table custom-height-group-items"
          >
            <div>
              <div className="product__current-value">
                {!state && newPrice ? `$${newPrice}` : "-"}
              </div>
              {!state ? (
                <div
                  className={
                    checkIsPositiveValue(range)
                      ? "product__new-value--positive"
                      : "product__new-value--negative"
                  }
                >
                  {range}
                </div>
              ) : null}
            </div>
          </h5>
        );
      },
      className: () => "table-w-90",
    },
    {
      content: ({ currentMargin, state, id, newMargin }: IProduct) => {
        const newPriceChooser = newMargin ? +newMargin : null;
        const range = findRangePercentage(+currentMargin, newPriceChooser);

        return (
          <>
            <h5
              onClick={(e) => {
                onOpenDrover(id, e);
              }}
              className="ta-right default-padding-blocks-table custom-height-group-items"
            >
              <div>
                <div className="product__current-value">
                  {!state && newMargin ? `${newMargin}%` : "-"}
                </div>
                {!state ? (
                  <div
                    className={
                      checkIsPositiveValue(range)
                        ? "product__new-value--positive"
                        : "product__new-value--negative"
                    }
                  >
                    {range}
                  </div>
                ) : null}
              </div>
            </h5>
          </>
        );
      },
      className: () => "table-w-90",
    },
    {
      content: ({ cost, id }: IProduct) => (
        <h5
          onClick={(e) => {
            onOpenDrover(id, e);
          }}
          className="ta-right custom-height-group-items default-padding-blocks-table"
        >
          ${cost}
        </h5>
      ),
      className: () => "table-w-90",
    },

    {
      content: ({ brand, id }: IProduct) => (
        <div
          onClick={(e) => {
            onOpenDrover(id, e);
          }}
          className="custom-height-group-items jc-start default-padding-blocks-table"
        >
          <h5 className="resize-block-brand">{brand}</h5>
        </div>
      ),
      className: () => "table-w-120 ",
    },
    {
      content: ({ store, id }: IProduct) => (
        <div
          onClick={(e) => {
            onOpenDrover(id, e);
          }}
          className="custom-height-group-items jc-start default-padding-blocks-table"
        >
          <h5 className="resize-block-store">{store}</h5>
        </div>
      ),
      className: () => "table-w-95",
    },
    {
      content: ({ last_reprice, id }: IProduct) => (
        <h5
          onClick={(e) => {
            onOpenDrover(id, e);
          }}
          className="custom-height-group-items default-padding-blocks-table"
        >
          {moment(last_reprice).format("MMM D, YYYY, HH:mm")}
        </h5>
      ),
      className: () => "table-w-157",
    },
  ];

  const dataTableBody = productsInGroup.map((item) => {
    return dataTableBodyCompare.map((el) => ({
      element: el.content(item),
      isSelected: item.id === activeProductId,
      className: el?.className(item) || "",
      item: item,
    }));
  });

  const handleScroll = (event: any) => {
    const { scrollLeft } = event.target;

    const maxScrollLeft = event.target.scrollWidth - event.target.clientWidth;

    setWidthOfScroll(tableWidth - 460 - maxScrollLeft);

    setScrollLeft(scrollLeft);
  };

  const onOpenDrover = (id: string, e: any) => {
    showItemInfoDrawer(id);
    e.stopPropagation();
  };

  const checkIfScrollable = () => {
    if (containerRef.current) {
      const { scrollWidth, clientWidth } = containerRef.current;
      setIsScrollable(scrollWidth > clientWidth);
      const maxScrollLeft = scrollWidth - clientWidth;

      setWidthOfScroll(tableWidth - 450 - maxScrollLeft);
    }
  };

  return (
    <>
      <div
        className={`hidden-scroll ${
          isLoading ? "clear-table-overflow" : "group-items__table"
        }`}
        style={{ width: tableWidth }}
        onScroll={handleScroll}
        ref={containerRef}
      >
        <TableCustom
          headData={headDataTable}
          bodyData={dataTableBody}
          handleSort={handleSort}
        />
        {!currentBrand.length ? (
          <>
            <div className="group-items__no-results">
              No results found. Please select at least one brand to see data.
            </div>
            <Divider />
          </>
        ) : null}
        <div
          onMouseDown={handleMouseDown}
          className="custom-scrollbar-block"
          style={{ width: tableWidth - 460 }}
        >
          {isScrollable && !isLoading && (
            <div
              className="custom-scrollbar"
              style={{
                left: `${scrollLeft}px`,
                width: `${widthOfScroll}px`,
              }}
            ></div>
          )}
        </div>
      </div>
      {currentBrand.length ? (
        <>
          <div className="pagination__container group-items__pagination-container">
            <div></div>
            <Pagination
              prevPage={definePrefPage}
              nextPage={defineNextPage}
              onChange={onChangeItemsCount}
              recordsCount={definePageCount()}
              currentLimit={defineCurrentLimit()}
              currentSelectLimit={currentLimit.toString()}
              currentOffset={defineCurrentOffset()}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

export default TableItems;
