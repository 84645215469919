import { useEffect, useState } from "react";
import "./groupItems.scss";

import { FilterItem } from "@features/filterItem";
import { useParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import {
  SortParams,
  IFilterItem,
  ISortParams,
  IDataTransfer,
} from "@shared/interfaces";
import {
  InputCustom,
  LoaderItemsTable,
  MultipleSelect,
} from "@shared/ui/components";
import {
  EGroupStatus,
  NEW_REPRICE_READY,
  useGroupsStore,
} from "@entities/groups";
import { useUserSettingsStore } from "@entities/userSettings";
import {
  BRAND_PARAM,
  LIMIT_PARAM,
  OFFSET_PARAM,
  SEARCH_PARAM,
  GROUP_ID_PARAM,
  ORDER_BY_PARAM,
  DIRECTION_PARAM,
  FILTER_BY_PARAM,
  DEFAULT_OFFSET_PAGINATION,
  DEFAULT_LIMIT_PAGINATION,
} from "@shared/constants";
import { Search } from "@shared/ui/assets";
import { filteredCompareQueryObj } from "@shared/utils";
import { ItemInfo } from "../../ItemInfo";
import TableItems from "../../ItemInfo/ui/TableItems/TableItems";

const allDefault = "All";

const GroupItems = () => {
  const { id } = useParams();

  const [inputValue, setInputValue] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [activeFilter, setActiveFilter] = useState<string>(allDefault);
  const [currentBrand, setCurrentBrand] = useState<string[]>([]);
  const [activeProductId, setActiveProductId] = useState<string>("");
  const [isInfoDrawerShow, setShowInfoDrawer] = useState<boolean>(false);

  const productsInGroup = useGroupsStore((state) => state.productsInGroup);
  const [tableWidth, setTableWidth] = useState<number>(500);
  const [currentLimit, setCurrentLimit] = useState(DEFAULT_LIMIT_PAGINATION);
  const [currentOffset, setCurrentOffset] = useState(DEFAULT_OFFSET_PAGINATION);

  const {
    getProductsInGroup,
    getBrandsFromGroup,
    brands,
    setProductsInGroup,
    setBrands,
    isLoading,
    currentGroup,
    seTisInitialLoadingGroupItems,
    isInitialLoadingGroupItems,
  } = useGroupsStore((state) => state);
  const { getCountNewPriceReady, productsNewRepriceReady } = useGroupsStore(
    (state) => state
  );
  const { asideCollapsed } = useUserSettingsStore((state) => state);
  const status = useGroupsStore((state) => state.currentGroup.status);
  const adaptedBrands = brands?.map((brand) => ({
    label: <>{brand.name}</>,
    value: brand.name,
  }));

  const brandsCompare = [...adaptedBrands];

  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: ISortParams;
  }>({
    key: "",
    direction: SortParams.ASC,
  });

  useEffect(() => {
    const selectedBrands = brandsCompare.map((option) => option.value);
    setCurrentBrand(selectedBrands);
  }, [brands]);

  useEffect(() => {
    getCountNewPriceReady(id || "");
    return () => {
      setProductsInGroup([]);
      seTisInitialLoadingGroupItems(true);
    };
  }, []);

  useEffect(() => {
    const chooseActiveFilter = activeFilter === allDefault ? "" : activeFilter;

    if (currentBrand.length) {
      const data = {
        [GROUP_ID_PARAM]: id,
        [BRAND_PARAM]: currentBrand,
        [LIMIT_PARAM]: currentLimit,
        [SEARCH_PARAM]: searchValue,
        [OFFSET_PARAM]: currentOffset,
        [ORDER_BY_PARAM]: sortConfig.key,
        [DIRECTION_PARAM]: sortConfig.direction,
        [FILTER_BY_PARAM]: chooseActiveFilter,
      };

      const filteredData = filteredCompareQueryObj(data) as IDataTransfer;

      getProductsInGroup(filteredData);
      setShowInfoDrawer(false);
    }

    return () => {
      setProductsInGroup([]);
    };
  }, [
    sortConfig,
    currentLimit,
    currentOffset,
    currentBrand,
    searchValue,
    activeFilter,
    id,
  ]);

  useEffect(() => {
    getBrandsFromGroup();
    return () => {
      setBrands([]);
    };
  }, [currentGroup.id]);

  useEffect(() => {
    const drawerCloseWidth = 72;
    const drawerOpenWidth = 248;

    if (asideCollapsed) {
      setTableWidth(
        window.innerWidth - drawerOpenWidth - window.innerWidth / 100 - 8 - 26
      );
    } else {
      setTableWidth(window.innerWidth - drawerCloseWidth - 55);
    }
  }, [asideCollapsed, productsInGroup]);

  //TODO
  const filterItems: IFilterItem[] = [
    {
      name: allDefault,
      value: allDefault,
      //TODO
      // element: (
      //   <h4  className="filter__text filter__text-badge">
      //     {filtersGroupAll || ""}
      //   </h4>
      // ),
      className: "",
    },
    {
      name: `New price ready (${productsNewRepriceReady})`,
      value: NEW_REPRICE_READY,
      // element: (
      //   <h4  className="filter__text filter__text-badge">
      //     {filters?.groupInRepriceReady || 0}
      //   </h4>
      // ),
      className: "filter__new-price-ready",
    },
  ];

  const onChangeBrand = (values: string[]) => {
    setCurrentBrand(values);
  };

  const debounced = useDebouncedCallback(async (value) => {
    setSearchValue(value);
  }, 500);

  const onSearchChange = (event: any) => {
    const value = event.target.value;
    setInputValue(value);
    debounced(value);
  };

  const onChangeFilter = (value: string) => {
    setActiveFilter(value);
    setActiveProductId("");
  };

  const isProcessStatus = status === EGroupStatus.IN_PROGRESS;

  return (
    <>
      {!isInitialLoadingGroupItems ? (
        <>
          <div className={isProcessStatus ? "disabled-no-effect" : ""}>
            {isInfoDrawerShow && (
              <ItemInfo
                onClose={(flag: boolean) => {
                  setShowInfoDrawer(flag);
                  setActiveProductId("");
                }}
                productId={activeProductId}
              />
            )}
            {isLoading ? <LoaderItemsTable /> : null}
            <div className="df mb-16">
              <div className="mr-32">
                <InputCustom
                  type="text"
                  value={inputValue}
                  name="group-search"
                  placeholder="Search for items"
                  onChangeInput={onSearchChange}
                  fill="gray-primary-900"
                  iconRight={<Search />}
                />
              </div>
              <div className="group-items__filters">
                {filterItems.map((item) => (
                  <FilterItem
                    item={item}
                    key={item.value}
                    active={activeFilter}
                    onClick={(value) => onChangeFilter(value)}
                    className={item.className}
                  />
                ))}
              </div>
              <div>
                <div className={`group-items-select__container`}>
                  <h5 className="group-items-select__title">Brand</h5>
                  <MultipleSelect
                    selectedItems={currentBrand}
                    options={brandsCompare}
                    onChange={onChangeBrand}
                    className={"multiselect_brands"}
                  />
                </div>
              </div>
            </div>
            <TableItems
              setSortConfig={setSortConfig}
              tableWidth={tableWidth}
              setShowInfoDrawer={setShowInfoDrawer}
              setActiveProductId={setActiveProductId}
              sortConfig={sortConfig}
              activeProductId={activeProductId}
              currentBrand={currentBrand}
              setCurrentLimit={setCurrentLimit}
              setCurrentOffset={setCurrentOffset}
              currentOffset={currentOffset}
              currentLimit={currentLimit}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

export default GroupItems;
