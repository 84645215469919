import "./layoutWithAside.scss";
import { useEffect } from "react";
import { ROUTES } from "@shared/interfaces";
import { Layout, Popover, Divider } from "antd";
import { NavLink } from "react-router-dom";
import {
  Dots,
  Profile,
  GroupIcon,
  ArrowBorder,
  SettingsIcon,
  KnowledgeBase,
  Notification,
} from "@shared/ui/assets";
import { useUserStore } from "@entities/user";
import { useShallow } from "zustand/react/shallow";
import NotificationsAside from "./NotificationsAside";
import ProfilePopoverContent from "./ProfilePopoverContent";
import { Badge as CustomBadge } from "@shared/ui/components";
import { useUserSettingsStore } from "@entities/userSettings";

const { Content } = Layout;

interface IProps {
  children: React.ReactElement;
}

const LayoutWithAside = ({ children }: IProps) => {
  const { logOut, getNotifications } = useUserStore((state) => state);
  const { setAsideCollapsed, asideCollapsed } = useUserSettingsStore(
    (state) => state
  );
  const user = useUserStore(useShallow((state) => state.user));
  const notifications = useUserStore(
    useShallow((state) => state.notifications)
  );

  useEffect(() => {
    async function fetchAPI() {
      await getNotifications();
    }

    fetchAPI();
  }, []);

  const ProfilePopover = () => (
    <Popover
      content={<ProfilePopoverContent user={user} logOut={logOut} />}
      placement="right"
      trigger="click"
      arrow={false}
      overlayClassName="aside-popover__profile"
    >
      <div className="aside__item ">
        <div className="df ai-center">
          <div className="aside__item-ico">
            <Profile />
          </div>
          {asideCollapsed && <h5 className="aside__link">Settings</h5>}
        </div>
        {asideCollapsed && (
          <div className="aside__actions">
            <Dots />
          </div>
        )}
      </div>
    </Popover>
  );

  const NotificationPopover = () => (
    <Popover
      content={<NotificationsAside />}
      placement="right"
      trigger="click"
      arrow={false}
      overlayClassName="aside-popover__notifications"
    >
      <div className="aside__item aside__margin-corection">
        <div className="aside__item-ico ">
          <Notification isShow={!!notifications.length} />
        </div>
        {asideCollapsed && <h5 className="aside__link">Notifications</h5>}
        {asideCollapsed && (
          <CustomBadge
            count={notifications.length}
            className="aside__actions badge-default"
            fill="gray-600"
          />
        )}
      </div>
    </Popover>
  );

  const onCollapse = () => {
    const collapseValue = !asideCollapsed ? 1 : 0;
    setAsideCollapsed(!!collapseValue);
  };

  const renderSidebar = () => {
    return (
      <div
        className={`${
          asideCollapsed ? "aside__container" : "aside__container-active"
        }`}
      >
        <div className="demo-logo-vertical">
          <h4>ReLU</h4>
        </div>

        <div className="aside__menu">
          <div className="aside__menu-top">
            <div className="aside__item">
              <NavLink
                to={`${ROUTES.ADMIN_GROUPS}?filterBy=ALL&offset=0&limit=10`}
                className="aside__link"
              >
                <div className="aside__item-ico">
                  <GroupIcon />
                </div>
                {asideCollapsed && <h5>Groups</h5>}
              </NavLink>
            </div>

            <div className="aside__item">
              <NavLink to={ROUTES.ADMIN_SETTINGS} className="aside__link">
                <div className="aside__item-ico">
                  <SettingsIcon />
                </div>
                {asideCollapsed && <h5>Settings</h5>}
              </NavLink>
            </div>
          </div>

          <div className="aside__menu-bottom">
            <div className="aside__item disabled">
              <NavLink to={ROUTES.ADMIN_KNOWLEDGE_BASE} className="aside__link">
                <div className="aside__item-ico">
                  <KnowledgeBase />
                </div>
                {asideCollapsed && " Knowledge Base"}
              </NavLink>
            </div>

            <NotificationPopover />
            <ProfilePopover />

            <Divider className="mb-8 mt-8" />

            <div
              className="aside__item aside__arrow"
              onClick={() => onCollapse()}
            >
              <div className="aside__item-ico aside__arrow">
                <ArrowBorder />
              </div>
              {asideCollapsed && (
                <h5 className="aside__link">Collapse menu </h5>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="layout__container">
      {renderSidebar()}
      <Layout>
        <Content>{children}</Content>
      </Layout>
    </div>
  );
};

export default LayoutWithAside;
