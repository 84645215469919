import { CurrentStep, PriceRoundingEnum, STEP_KEYS } from "@entities/groups";
import ReactDOMServer from "react-dom/server";

export const getItemWord = (count: number): string => {
  return count === 1 ? "item" : "items";
};

export const getCompetitorWord = (count: number): string => {
  return count === 1 ? "competitor" : "competitors";
};

export const filteredCompareQueryObj = (compareQueryObj: any) => {
  return Object.fromEntries(
    Object.entries(compareQueryObj).filter(
      ([key, value]) => value !== undefined && value !== null && value !== ""
    )
  );
};

interface IAjustPriceValue {
  decimalMain?: string | null;
  integerMain?: string | null;
  round?: PriceRoundingEnum;
  baseNum?: string;
}

export const adjustPriceValue = ({
  decimalMain = null,
  integerMain = null,
  round = PriceRoundingEnum.ROUND_DOWN,
  baseNum = "123.43",
}: IAjustPriceValue) => {
  const [integer, decimal] = baseNum.split(".");
  const isInteger = (num: any) => Number.isInteger(num);
  const roundedUp = (number: number) => Math.ceil(number / 10) * 10;
  const roundedDown = (number: number) => Math.round(number / 10) * 10;

  const chooseDecimal =
    decimalMain && decimalMain.length === 1
      ? `0${decimalMain}`
      : `${decimalMain}`;
  if (
    decimalMain &&
    isInteger(+decimalMain) &&
    integerMain &&
    isInteger(+integerMain)
  ) {
    // zeros
    if (
      (decimalMain === "0" || decimalMain === "00") &&
      integerMain === "0" &&
      round === PriceRoundingEnum.ROUND_DOWN
    ) {
      return `${roundedDown(+integer)}.00`;
    }

    if (
      (decimalMain === "0" || decimalMain === "00") &&
      integerMain === "0" &&
      round === PriceRoundingEnum.ROUND_UP
    ) {
      return `${roundedUp(+integer)}.00`;
    }

    // other numbs
    if (round === PriceRoundingEnum.ROUND_DOWN) {
      if (+integerMain < +integer[2]) {
        return `${integer[0]}${+integer[1]}${integerMain}.${chooseDecimal}`;
      }
      if (+integerMain > +integer[2]) {
        return `${integer[0]}${+integer[1] - 1}${integerMain}.${chooseDecimal}`;
      }
    }

    if (round === PriceRoundingEnum.ROUND_UP) {
      if (+integerMain < +integer[2]) {
        return `${integer[0]}${+integer[1] + 1}${integerMain}.${chooseDecimal}`;
      }
      if (+integerMain > +integer[2]) {
        return `${integer[0]}${+integer[1]}${integerMain}.${chooseDecimal}`;
      }
    }

    // isEqual

    if (integerMain === integer[2]) {
      if (round === PriceRoundingEnum.ROUND_DOWN) {
        if (+decimalMain > +decimal) {
          return `${integer[0]}${
            +integer[1] - 1
          }${integerMain}.${chooseDecimal}`;
        }
        if (+decimalMain < +decimal) {
          return `${integer[0]}${+integer[1]}${integerMain}.${chooseDecimal}`;
        }
      }

      if (round === PriceRoundingEnum.ROUND_UP) {
        if (+decimalMain > +decimal) {
          return `${integer[0]}${+integer[1]}${integerMain}.${chooseDecimal}`;
        }
        if (+decimalMain < +decimal) {
          return `${integer[0]}${
            +integer[1] + 1
          }${integerMain}.${chooseDecimal}`;
        }
      }
    }
  }

  if (integerMain && round === PriceRoundingEnum.ROUND_DOWN) {
    if (+integerMain < +integer[2]) {
      return `${integer[0]}${+integer[1]}${integerMain}.${decimal}`;
    }
    if (+integerMain > +integer[2]) {
      return `${integer[0]}${+integer[1] - 1}${integerMain}.${decimal}`;
    }
    if (integerMain === integer[2]) {
      return baseNum;
    }
  }

  if (integerMain && round === PriceRoundingEnum.ROUND_UP) {
    if (+integerMain < +integer[2]) {
      return `${integer[0]}${+integer[1] + 1}${integerMain}.${decimal}`;
    }
    if (+integerMain > +integer[2]) {
      return `${integer[0]}${integer[1]}${integerMain}.${decimal}`;
    }
    if (integerMain === integer[2]) {
      return baseNum;
    }
  }

  if (decimalMain && round === PriceRoundingEnum.ROUND_DOWN) {
    if (decimalMain < decimal) {
      return `${+integer}.${chooseDecimal}`;
    }
    if (decimalMain > decimal) {
      return `${+integer - 1}.${chooseDecimal}`;
    }
  }

  if (decimalMain && round === PriceRoundingEnum.ROUND_UP) {
    if (decimalMain < decimal) {
      return `${+integer + 1}.${chooseDecimal}`;
    }
    if (decimalMain > decimal) {
      return `${integer}.${chooseDecimal}`;
    }
  }
  return baseNum;
};

export async function loadStateWrapper<T>(
  set: any,
  asyncAction: Promise<T>,
  key?: string
): Promise<T> {
  const actualKey = key || "isLoading";
  set({ [actualKey]: true, error: null });
  try {
    const res = await asyncAction;
    return res;
  } catch (error) {
    throw error;
  } finally {
    set({ [actualKey]: false });
  }
}

export const htmlToString = (html: React.ReactNode) => {
  return ReactDOMServer.renderToString(html);
};

export const sortAlertsToStrategy = (strategy: any) => {
  const modifiedStrategy: { [key: string]: any } = {};

  Object.keys(strategy).forEach((key: string) => {
    const currentKey = strategy[key];
    const alerts = currentKey?.alerts;

    if (!alerts) {
      modifiedStrategy[key] = currentKey;
    } else {
      modifiedStrategy[key] = {
        ...strategy[key],
        alerts: alerts.sort((a: any, b: any) => a.priority - b.priority),
      };
    }
  });
  return modifiedStrategy;
};

export const arrayToQueryParams = (arr: any[], key: string) => {
  return arr
    .map((brand: any) => `${key}=${encodeURIComponent(brand)}`)
    .join("&");
};

export const findRangePercentage = (
  num1: number | null,
  num2: number | null
) => {
  if (num1 === null || num2 === null) {
    return "";
  }
  const percentageChange = ((num2 - num1) / Math.abs(num1)) * 100;

  const sign = percentageChange > 0 ? "+" : "";
  return percentageChange || percentageChange === 0
    ? `${sign}${percentageChange.toFixed(2)}%`
    : "";
};
export const checkIsPositiveValue = (string: string) => {
  return string.includes("-") ? false : true;
};

const calculateSales = (minTrend: any, maxTrend: any, newPrice: number) => {
  const period = maxTrend.price - minTrend.price;
  const dev = newPrice - minTrend.price;
  const percent = (dev * 100) / period;
  const finalSale = ((minTrend.sales - maxTrend.sales) / 100) * percent;

  return +(minTrend.sales - finalSale).toFixed(0);
};
const calculateCost = (minTrend: any, maxTrend: any, newPrice: number) => {
  const period = maxTrend.price - minTrend.price;
  const dev = newPrice - minTrend.price;
  const percent = (dev * 100) / period;
  const finalCost =
    minTrend.cost + (maxTrend.cost - minTrend.cost) * (percent / 100);
  return finalCost;
};
const calculateRevenue = (sales: number, price: number, cost: number) => {
  return +(sales * (price - cost)).toFixed(2);
};
const calculateProfit = (sales: number, price: number) => {
  return +(sales * price).toFixed(0);
};

export const calculateActiveTrend = (
  minTrend: any,
  maxTrend: any,
  newPrice: number
) => {
  const sales = calculateSales(minTrend, maxTrend, newPrice);
  const cost = calculateCost(minTrend, maxTrend, newPrice);

  return {
    sales,
    cost,
    price: newPrice,
    revenue: calculateRevenue(sales, newPrice, cost),
    profit: calculateProfit(sales, newPrice),
  };
};

export const removeEmptySearchParams = (searchParams: URLSearchParams) => {
  const updatedParams = new URLSearchParams();
  searchParams.forEach((value, key) => {
    if (value) {
      updatedParams.append(key, value);
    }
  });
  return updatedParams;
};

export const isValidLimitValue = (value: string) => {
  value = value.replace(",", ".");

  const regexValid = /^(\d{1,2}(\.\d{0,2})?)?$/.test(value);

  const noAdditionalAfterZero =
    value === "" ||
    value === "0" ||
    value === "0." ||
    /^[1-9]\d?(\.\d{0,2})?$/.test(value) ||
    /^0\.\d{0,2}$/.test(value);

  return regexValid && noAdditionalAfterZero;
};

export const capitalizeFirstLetter = (string: string) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const generateId = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (char) => {
    const random = (Math.random() * 16) | 0;
    const value = char === "x" ? random : (random & 0x3) | 0x8;
    return value.toString(16);
  });
};

export const getParamsAsObject = (
  searchParams: URLSearchParams
): Record<string, string> => {
  const params: Record<string, string> = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  });
  return params;
};
