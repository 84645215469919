import { EGroupStatus, IGroup, useGroupsStore } from "@entities/groups";
import { IProduct, useProductsStore } from "@entities/products";
import { useUserStore } from "@entities/user";
import {
  GROUP_EVENTS,
  ITEM_EVENTS,
  SOCKET_DEFAULT_EVENTS,
} from "@shared/constants/socketConstants";
import socket from "@shared/utils/configs/socket";
import { useEffect, useState } from "react";

const useSocket = () => {
  const {
    CONNECT,
    DISCONNECT,
    CONNECT_ERROR,
    CONNECT_TIMEOUT,
    PING,
    PONG,
    ERROR,
  } = SOCKET_DEFAULT_EVENTS;
  const [isConnected, setIsConnected] = useState(false);
  const {
    setCompletedNewPricingEvent,
    setNewStatusGroup,
    getCountNewPriceReady,
    changeProduct,
    setNewStatusToGroups,
    setRepricedCountNotification,
  } = useGroupsStore((state) => state);

  useEffect(() => {
    socket.on(CONNECT, () => {
      setIsConnected(true);
      console.log("Socket ID:", socket.id);
    });

    socket.on(DISCONNECT, () => {
      setIsConnected(false);
      console.log("Disconnected from socket server useSocket");
    });

    socket.on(
      GROUP_EVENTS.APPLYING_PRICES_SUCCESSFUL,
      async (data: {
        payload: { newGroup: IGroup; repricedCount: number };
      }) => {
        // setRepricedCountNotification(data.payload.repricedCount);
        // setCompletedNewPricingEvent(true);
        // setNewStatusGroup(EGroupStatus.COMPLETED);
        // setNewStatusToGroups(data.payload.newGroup);
        // await useGroupsStore.getState().getProductsInGroup({});
        // await getCountNewPriceReady();
        // console.log(data, "data");
      }
    );
    socket.on(
      ITEM_EVENTS.APPLYING_PRICE_SUCCESSFUL,
      async (data: { payload: IProduct }) => {
        // await getCountNewPriceReady();
        // changeProduct(data.payload);
      }
    );

    socket.on(CONNECT_ERROR, (err: any) => {
      console.error("Connection error:", err);
    });

    socket.on(CONNECT_TIMEOUT, () => {
      console.error("Connection timed out");
    });

    socket.on(ERROR, (err: any) => {
      console.error("Socket error:", err);
    });

    socket.on(PONG, () => {
      console.log("Received pong from server");
    });

    const pingInterval = setInterval(() => {
      if (socket.connected) {
        socket.emit(PING, (response: any) => {
          console.log("Ping response from server:", response);
        });
      } else {
        console.log("Socket is not connected, skipping ping");
      }
    }, 90000);

    return () => {
      socket.off(CONNECT);
      socket.off(DISCONNECT);
      socket.off(CONNECT_ERROR);
      socket.off(CONNECT_TIMEOUT);
      socket.off(ERROR);
      socket.off(PONG);
      clearInterval(pingInterval);
    };
  }, []);

  const emitEvent = (event: string, data: any) => {
    socket.emit(event, data);
  };
  return {
    isConnected,
    emitEvent,
  };
};

export default useSocket;
