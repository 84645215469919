import { immer } from "zustand/middleware/immer";
import { devtools } from "zustand/middleware";
import { create } from "zustand";

import { IInviteUser, ISettingsStore, UserRolesEnum } from "./settings.types";
import settingsService from "../api";
import { IDataTransfer } from "@shared/interfaces";

export const useSettingsStore = create<ISettingsStore>()(
  devtools(
    immer((set, get) => ({
      usersTable: [],
      inviteUsersErrors: [],
      recordsCountUsers: 0,
      getUsersTable: async (params: IDataTransfer) => {
        try {
          const { data } = await settingsService.getUsers(params);
          set({
            usersTable: data.users,
            recordsCountUsers: data.count,
          });
          return data;
        } catch (e) {
          console.log(e);
        }
      },
      saveInviteUser: async (inviteUser: IInviteUser) => {
        const { data } = await settingsService.inviteUsers(inviteUser);
      },
      deleteUserById: async (id: string) => {
        const { data } = await settingsService.deleteUserById(id);
      },
      editUserById: async (id: string, role: UserRolesEnum) => {
        const { data } = await settingsService.editUserById(id, role);
      },
    }))
  )
);
