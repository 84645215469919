import { immer } from "zustand/middleware/immer";
import { devtools } from "zustand/middleware";
import { create } from "zustand";
import { IUser, IUserState } from "@entities/user/model/user.types";
import { IFormLoginState } from "@shared/interfaces";
import { SUCCESS_STATUS } from "@shared/interfaces/response";
import userService from "../api";
import { errorHandler } from "@shared/utils";

const initUser = {
  productCategories: [],
};
// immer -  for mutate state directly
export const useUserStore = create<IUserState>()(
  devtools(
    immer((set, get) => ({
      user: initUser,
      notifications: [],
      checkAuth: async () => {
        try {
          const { data } = await userService.checkAuth();

          const { accessToken, user } = data;
          localStorage.setItem("_accessToken", accessToken);
          set({
            user,
          });
          return data;
        } catch (e) {
          set({ user: {} });
        }
      },
      refreshToken: async () => {
        // const { data } = await userService.refreshToken();
        // if (data?.status === ERROR_STATUS.ACCESS_DENIED_403) {
        //   localStorage.removeItem("_accessToken");
        //   return set({ user: {} });
        // }
        // const { accessToken, user } = data;
        // localStorage.setItem("_accessToken", accessToken);
        // get().setUser(user);
      },
      setUser: (userData: IUser) => {
        set({ user: userData });
      },
      logIn: async (formData: IFormLoginState) => {
        try {
          const res = await userService.login(formData);

          const data = res.data;
          if (data?.status) {
            throw data;
          }
          const { accessToken, user } = data;
          localStorage.setItem("_accessToken", accessToken);
          get().setUser(user);

          return user;
        } catch (e) {
          const customError: any = errorHandler.isResponseHandled(e);

          if (customError) {
            throw customError;
          }
        }
      },
      logOut: async () => {
        const userId = get().user.id || "";
        const { status } = await userService.logout(userId);

        if (status === SUCCESS_STATUS.SUCCESS_204) {
          set({ user: {} });
          localStorage.removeItem("_accessToken");
        }
      },
      getNotifications: async () => {
        const userId = get().user.id || "";

        const { data } = await userService.getNotifications(userId);
        set({ notifications: data });
      },
    }))
  )
);
