import { ApiService } from "@shared/utils";
import { url } from "@shared/utils/url.utils";
import { IDataTransfer, IQuery } from "@shared/interfaces";
import { IInviteUser, UserRolesEnum } from "../model/settings.types";

class SettingsService extends ApiService {
  settingsURL: string;

  constructor() {
    super();

    this.settingsURL = "/api/v1";
  }

  async getUsers(queryParams: IDataTransfer) {
    let params = url.generateQueryString(queryParams);

    const currentUrl = `${this.settingsURL}/users/?${params}`;

    return await this.baseServiceInstance.get(currentUrl);
  }
  async inviteUsers(inviteUser: IInviteUser) {
    return await this.baseServiceInstance.post(
      `${this.settingsURL}/users/invite`,
      inviteUser
    );
  }
  async deleteUserById(id: string) {
    return await this.baseServiceInstance.delete(
      `${this.settingsURL}/users/${id}`
    );
  }
  async editUserById(id: string, role: UserRolesEnum) {
    return await this.baseServiceInstance.patch(
      `${this.settingsURL}/users/${id}/roles`,
      {
        role,
      }
    );
  }
}

const settingsService = new SettingsService();

export default settingsService;
