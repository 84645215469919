import { ApiService, arrayToQueryParams } from "@shared/utils";
import { Period } from "../model/itemInfo.types";
import { url } from "@shared/utils/url.utils";
import { IDataTransfer } from "@shared/interfaces";
class ItemInfoService extends ApiService {
  baseURL: string;

  constructor() {
    super();

    this.baseURL = "/api/v1";
  }

  async getProductsInGroup(data: IDataTransfer) {
    const { groupId, ...allParams } = data;
    let paramsBrands = "";

    if (data.brand) {
      paramsBrands = data.brand
        .map((brand: any) => `brand=${encodeURIComponent(brand)}`)
        .join("&");
    }

    delete allParams.brand;

    let params = url.generateQueryString(allParams || {});

    return await this.baseServiceInstance.get(
      `${this.baseURL}/groups/${groupId}/products?${params}&${paramsBrands}`
    );
  }
  async getItemCompetitors(productId: string) {
    return await this.baseServiceInstance.get(
      `${this.baseURL}/products/${productId}/competitors`
    );
  }
  async changeMonitoringStatus(id: number, monitoringStatus: boolean) {
    return await this.baseServiceInstance.patch(
      `${this.baseURL}/products/competitors/monitoring/${id}`,
      {
        monitoringStatus,
      }
    );
  }
  async getDatesOfGraphCompetitors(
    key: string,
    productId: string,
    competitorsIds: any[]
  ) {
    const competitorsIdsString = arrayToQueryParams(
      competitorsIds,
      "competitorProductId"
    );

    let params = url.generateQueryString(competitorsIdsString);

    return await this.baseServiceInstance.get(
      `${this.baseURL}/competitors/trends?from=${key}&productId=${productId}&${params}`
    );
  }
  async getElasticityDataGraph(productId: string) {
    return await this.baseServiceInstance.get(
      `${this.baseURL}/products/${productId}/elasticity/trends`
    );
  }
}

const itemInfoService = new ItemInfoService();

export default itemInfoService;
