import { IUser } from "@entities/user";
import {
  ForgotPasswordPage,
  LogInPage,
  NotFoundPage,
  SignInPage,
  GroupsPage,
  SettingsPage,
  KnowledgePage,
  ProfilePage,
  CreateGroupPage,
  GroupPage,
} from "@pages/index";
import { IRoute, ROUTES } from "@shared/interfaces";
import { Navigate } from "react-router-dom";

export class RouterBuilder {
  baseRouters: IRoute[] = [];
  authRouter: IRoute[] = [];

  constructor() {
    this.baseRouters = [
      {
        path: ROUTES.BASE,
        element: <GroupsPage />,
      },
      {
        path: ROUTES.ADMIN_GROUPS,

        element: <GroupsPage />,
      },
      {
        path: ROUTES.ADMIN_GROUPS_ID,
        element: <GroupPage />,
      },
      {
        path: ROUTES.ADMIN_GROUPS_CREATE,
        element: <CreateGroupPage />,
      },
      {
        path: ROUTES.ADMIN_GROUPS_CREATE_ID,
        element: <CreateGroupPage />,
      },
      {
        path: ROUTES.ADMIN_SETTINGS,
        element: <SettingsPage />,
      },
      {
        path: ROUTES.ADMIN_KNOWLEDGE_BASE,
        element: <KnowledgePage />,
      },
      {
        path: ROUTES.ADMIN_PROFILE,
        element: <ProfilePage />,
      },

      {
        path: ROUTES.LOGIN,
        element: <Navigate to={ROUTES.BASE} />,
      },
      {
        path: ROUTES.FORGOT_PASSWORD,
        element: <Navigate to={ROUTES.BASE} />,
      },
      {
        path: ROUTES.NOT_FOUND,
        element: <NotFoundPage />,
      },
    ];

    this.authRouter = [
      {
        path: ROUTES.BASE,
        element: <LogInPage />,
      },
      {
        path: ROUTES.LOGIN,
        element: <LogInPage />,
      },
      {
        path: ROUTES.SIGN_IN,
        element: <Navigate to={ROUTES.BASE} />,
        // element: <SignInPage />,
      },
      {
        path: ROUTES.FORGOT_PASSWORD,
        element: <Navigate to={ROUTES.BASE} />,
        // element: <ForgotPasswordPage />,
      },
      {
        path: ROUTES.NOT_FOUND,
        element: <Navigate to={ROUTES.BASE} />,
      },
    ];
  }

  addAuthRouters(): RouterBuilder {
    this.baseRouters = [...this.authRouter];

    return this;
  }

  removeAuthRouters(): RouterBuilder {
    this.baseRouters.filter(({ path }: IRoute) => {
      return path !== ROUTES.LOGIN && path !== ROUTES.SIGN_IN;
    });

    return this;
  }

  build(): IRoute[] {
    return this.baseRouters;
  }
}
