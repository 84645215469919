import React from "react";
import "./loaderFullScreen.scss";
import { Spiner } from "@features/spiner";
const LoaderFullScreen = () => {
  return (
    <div className="loader-block">
      <Spiner />
    </div>
  );
};

export default LoaderFullScreen;
