import { FC } from "react";
import "./filterItem.scss";
import { IFilterItem } from "@shared/interfaces";
import { Typography } from "antd";

const { Title } = Typography;

interface IProps {
  active: string;
  item: IFilterItem;
  onClick: (value: string) => void;
  className?: string;
}

const FilterItem: FC<IProps> = ({
  active,
  item,
  onClick,
  className,
}: IProps) => {
  const initialClasses = ["filter__item"];
  const classes = className ? [...initialClasses, className] : initialClasses;

  if (active === item.value) {
    classes.push("filter__item-active");
  }

  return (
    <div className={classes.join(" ")} onClick={() => onClick(item.value)}>
      <Title level={4} className="filter__text mr-6">
        {item.name}
      </Title>
      {item.element}
    </div>
  );
};

export default FilterItem;
