import { LayoutWithAside, TeamManagement } from "@widgets/admin";
import { Tabs } from "antd";
import "./settings.scss";

const tabOptions = [
  {
    label: <h4 className="tab-title-page">Team Management</h4>,
    key: "teamManagement",
    disabled: false,
    children: <TeamManagement />,
  },
  {
    label: <h4 className="tab-title-page">Billing</h4>,
    key: "billing",
    disabled: true,
    children: <>2</>,
  },
  {
    label: <h4 className="tab-title-page">Integrations</h4>,
    key: "integrations",
    disabled: true,
    children: <>3</>,
  },
  {
    label: <h4 className="tab-title-page">System</h4>,
    key: "system",
    disabled: true,
    children: <>4</>,
  },
];

export const SettingsPage = () => {
  return (
    <LayoutWithAside>
      <>
        <h1 className="content__title pl-24 pt-30 pb-30">Settings</h1>

        <div>
          <div className="settings-page-tabs">
            <Tabs
              defaultActiveKey="0"
              items={tabOptions.map(
                ({ label, key, children, disabled }, ind) => {
                  return {
                    disabled: disabled,
                    label: label,
                    key: ind.toString(),
                    children: children,
                  };
                }
              )}
            />
          </div>
        </div>
      </>
    </LayoutWithAside>
  );
};

export default SettingsPage;
