import { EGroupStatus, useGroupsStore } from "@entities/groups";
import { Arrow, Dots, Info, Warning } from "@shared/ui/assets";
import { AsideDropdown, LayoutWithAside } from "@widgets/admin";
import { Divider, MenuProps, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./groupPage.scss";
import {
  AlertCustom,
  ButtonStyled,
  CustomTooltip,
  LoaderDefault,
  LoaderFullScreen,
  NotificationCustom,
} from "@shared/ui/components";
import { ROUTES } from "@shared/interfaces";
import { getMenuPropsItems } from "@shared/common";
import { GroupItems } from "@widgets/admin/group";
import { GroupDeletePopup } from "@widgets/admin/group/ui/GroupDeletePopup";
import { GroupEditPopup } from "@widgets/admin/group/ui/GroupEditPopup";
import { GroupApplyNewPricesPopup } from "@widgets/admin/group/ui/GroupApplyNewPricesPopup";
import { getItemWord } from "@shared/utils";

const tabOptions = [
  {
    label: <h4 className="tab-title-page">Overview</h4>,
    key: "overview",
    disabled: true,
    children: <></>,
  },
  {
    label: <h4 className="tab-title-page">Items</h4>,
    key: "items",
    disabled: false,
    children: <GroupItems />,
  },
  {
    label: <h4 className="tab-title-page">Configuration</h4>,
    key: "configuration",
    disabled: true,
    children: <></>,
  },
];

type MenuItem = Required<MenuProps>["items"][number];

const GroupPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { contextHolder, openNotification } = NotificationCustom();
  const groupName = useGroupsStore((state) => state.currentGroup.name);
  const status = useGroupsStore((state) => state.currentGroup.status);
  const isCompletedNewPricing = useGroupsStore(
    (state) => state.isCompletedNewPricing
  );
  const isInitialLoadingGroupItems = useGroupsStore(
    (state) => state.isInitialLoadingGroupItems
  );
  const productsNewRepriceReady = useGroupsStore(
    (state) => state.productsNewRepriceReady
  );
  const repricedCountNotification = useGroupsStore(
    (state) => state.repricedCountNotification
  );
  const {
    getGroup,
    clearGroup,
    clearStrategyInStore,
    setCompetitorsInGroup,
    applyNewPrices,
    setCompletedNewPricingEvent,
    setRepricedCountNotification,
    setNewStatusGroup,
    setCountNewPriceReady,
  } = useGroupsStore((state) => state);

  const [isDeleteGroupPopupShow, setDeleteGroupPopupShow] =
    useState<boolean>(false);
  const [isEditGroupPopupShow, setEditGroupPopupShow] =
    useState<boolean>(false);
  const [isApplyNewPricesPopupShow, setApplyNewPricesPopupShow] =
    useState<boolean>(false);

  useEffect(() => {
    if (id) {
      getGroup(id);
    }
  }, []);

  useEffect(() => {
    if (isCompletedNewPricing && groupName) {
      openNotification({
        message: `${repricedCountNotification} ${getItemWord(
          repricedCountNotification
        )} have been successfully repriced in ${groupName} group`,
        type: "success",
        placement: "bottom",
        onClose: () => {
          setCompletedNewPricingEvent(false);
          setRepricedCountNotification(0);
        },
      });
    }
    // if (status === EGroupStatus.FAILED && groupName) {
    //   openNotification({
    //     message: `Applying prices for ${groupName} failed`,
    //     type: "error",
    //     placement: "bottom",
    //   });
    // }
  }, [groupName, isCompletedNewPricing]);

  const isProcessStatus = status === EGroupStatus.IN_PROGRESS;

  const itemsTopMenu: MenuItem[] = [
    getMenuPropsItems(
      "Rename group",
      "rename",
      null,
      undefined,
      false,
      false,
      () => setEditGroupPopupShow(true)
    ),
    getMenuPropsItems("Export as CSV", "export", null, undefined, true),
    getMenuPropsItems(
      "Delete group",
      "delete",
      null,
      undefined,
      false,
      true,
      () => setDeleteGroupPopupShow(true)
    ),
  ];

  const handleClick = () => {
    navigate(ROUTES.ADMIN_GROUPS);
    clearGroup();
    clearStrategyInStore();
    setCompetitorsInGroup([]);
  };

  const onCloseDeletePopupHandler = (flag: boolean) => {
    setDeleteGroupPopupShow(flag);
  };

  const handleOpenPopupApplyNewPrices = () => {
    setApplyNewPricesPopupShow(true);
  };

  const onOkApplyNewPrices = async () => {
    setApplyNewPricesPopupShow(false);
    setNewStatusGroup(EGroupStatus.IN_PROGRESS);
    await applyNewPrices(id as string);
    setCountNewPriceReady(0);
  };

  const chooseTextBtnApplyNewPrices = () => {
    if (isProcessStatus) {
      return (
        <>
          {/* <LoaderBlock />  */}
          {`Applying prices (${productsNewRepriceReady})`}
        </>
      );
    }
    if (status === EGroupStatus.REPRICE_READY && productsNewRepriceReady > 0) {
      return `Apply new prices (${productsNewRepriceReady})`;
    }
    if (status === EGroupStatus.COMPLETED && productsNewRepriceReady > 0) {
      return `Apply new prices (${productsNewRepriceReady})`;
    }
    return `Apply new prices`;
  };

  const chooserTooltipText = () => {
    if (isProcessStatus) {
      return `Repricing in progress`;
    }
    if (status === EGroupStatus.REPRICE_READY && productsNewRepriceReady > 0) {
      return `${productsNewRepriceReady} ${getItemWord(
        productsNewRepriceReady
      )} ready to reprice`;
    }
    if (status === EGroupStatus.COMPLETED && productsNewRepriceReady > 0) {
      return `${productsNewRepriceReady} ${getItemWord(
        productsNewRepriceReady
      )} ready to reprice`;
    }
    return `No items to reprice`;
  };

  return (
    <>
      {contextHolder}
      <GroupDeletePopup
        flag={isDeleteGroupPopupShow}
        onClose={onCloseDeletePopupHandler}
      />
      <GroupEditPopup
        flag={isEditGroupPopupShow}
        onClose={setEditGroupPopupShow}
      />
      <GroupApplyNewPricesPopup
        flag={isApplyNewPricesPopupShow}
        onClose={() => setApplyNewPricesPopupShow(false)}
        onOk={() => onOkApplyNewPrices()}
      />
      {isInitialLoadingGroupItems ? <LoaderFullScreen /> : null}

      <LayoutWithAside>
        <div className="group-page">
          <div className="df ai-center jc-sb group-page-title">
            <div className="">
              <div className="back_to_container" onClick={handleClick}>
                <Arrow />
                <h5 className="">Back to Groups</h5>
              </div>
              <h1 className="">{groupName || <>&nbsp;</>}</h1>
            </div>

            {!isInitialLoadingGroupItems ? (
              <div className="edit-title__actions">
                <CustomTooltip title={chooserTooltipText()}>
                  <ButtonStyled
                    type="primary"
                    text={chooseTextBtnApplyNewPrices()}
                    htmlType="button"
                    fill="#fff3cd"
                    disabled={productsNewRepriceReady === 0}
                    className={`groups-empty__btn w-188 mr-16 ${
                      isProcessStatus && "disabled"
                    }`}
                    onClick={() => handleOpenPopupApplyNewPrices()}
                    iconPosition="start"
                    icon={isProcessStatus ? <LoaderDefault /> : undefined}
                  />
                </CustomTooltip>

                <div className={isProcessStatus ? "disabled" : ""}>
                  <AsideDropdown
                    collapsed={true}
                    items={itemsTopMenu}
                    placement="bottomRight"
                    className={"relu__menu"}
                  >
                    <div className="dots-title__btn">
                      <Dots color="#212529" />
                    </div>
                  </AsideDropdown>
                </div>
              </div>
            ) : null}
          </div>
          <Divider />

          {isProcessStatus ? (
            <div className="applying-prices-block">
              <div className="applying-prices-block__content">
                <div>
                  <LoaderDefault className="loader" />
                </div>
                <div className="block__text">
                  <h2 className="block__text__title">Applying prices...</h2>
                  <div className="block__text__subtitle">
                    It can take up to 1 minute.
                  </div>
                  <div className="block__text__subtitle">
                    Group actions temporarily disabled.
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div className="group-page-tabs">
            <Tabs
              defaultActiveKey="1"
              items={tabOptions.map(({ label, key, children }, ind) => {
                return {
                  disabled: true,
                  label: label,
                  key: ind.toString(),
                  children: children,
                };
              })}
            />
          </div>
        </div>
      </LayoutWithAside>
    </>
  );
};

export default GroupPage;
